import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  IconButton,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarIcon from '@mui/icons-material/Star';
import HistoryIcon from '@mui/icons-material/History';
import FolderIcon from '@mui/icons-material/Folder';
import BarChartIcon from '@mui/icons-material/BarChart';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { promptsService, promptExecutionsService } from '../../services/apiService';
import { useAuth } from '../../contexts/AuthContext';

const Dashboard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalPrompts: 0,
    favoritePrompts: 0,
    totalExecutions: 0,
    recentExecutions: [],
    popularPrompts: [],
    promptsByFolder: []
  });

  useEffect(() => {
    const loadDashboardData = async () => {
      if (!user) return;

      try {
        setLoading(true);
        
        // Get all prompts
        const prompts = await promptsService.getPrompts(user.id);
        
        // Get all executions
        const executions = await promptExecutionsService.getAllExecutions(user.id);
        
        // Calculate stats
        const favoritePrompts = prompts.filter(p => p.is_favorite);
        
        // Get recent executions (last 5)
        const recentExecutions = executions
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 5);
        
        // Calculate popular prompts (most executed)
        const promptExecutionCounts = {};
        executions.forEach(execution => {
          promptExecutionCounts[execution.prompt_id] = (promptExecutionCounts[execution.prompt_id] || 0) + 1;
        });
        
        const popularPrompts = Object.entries(promptExecutionCounts)
          .map(([promptId, count]) => {
            const prompt = prompts.find(p => p.id === promptId);
            return { prompt, count };
          })
          .filter(item => item.prompt) // Filter out any undefined prompts
          .sort((a, b) => b.count - a.count)
          .slice(0, 5);
        
        // Calculate prompts by folder
        const folderCounts = {};
        prompts.forEach(prompt => {
          const folder = prompt.folder || 'Default';
          folderCounts[folder] = (folderCounts[folder] || 0) + 1;
        });
        
        const promptsByFolder = Object.entries(folderCounts)
          .map(([folder, count]) => ({ folder, count }))
          .sort((a, b) => b.count - a.count);
        
        setStats({
          totalPrompts: prompts.length,
          favoritePrompts: favoritePrompts.length,
          totalExecutions: executions.length,
          recentExecutions,
          popularPrompts,
          promptsByFolder
        });
      } catch (error) {
        console.error('Error loading dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadDashboardData();
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>Dashboard</Typography>
        <Typography variant="body1" color="text.secondary">
          Welcome back! Here's an overview of your prompts and activity.
        </Typography>
      </Box>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Total Prompts</Typography>
              <Typography variant="h3">{stats.totalPrompts}</Typography>
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                component={Link} 
                to="/prompts"
                startIcon={<FolderIcon />}
              >
                View All
              </Button>
            </CardActions>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Favorite Prompts</Typography>
              <Typography variant="h3">{stats.favoritePrompts}</Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                component={Link}
                to="/library?filter=favorites"
                startIcon={<StarIcon />}
              >
                View Favorites
              </Button>
            </CardActions>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Total Executions</Typography>
              <Typography variant="h3">{stats.totalExecutions}</Typography>
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                component={Link} 
                to="/history"
                startIcon={<HistoryIcon />}
              >
                View History
              </Button>
            </CardActions>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Create New</Typography>
              <Typography variant="body2" color="text.secondary">
                Create a new prompt to add to your collection
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                component={Link} 
                to="/editor/new"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
              >
                New Prompt
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Recent Activity and Popular Prompts */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Recent Activity</Typography>
              <Divider sx={{ mb: 2 }} />
              
              {stats.recentExecutions.length > 0 ? (
                <List>
                  {stats.recentExecutions.map((execution) => (
                    <ListItem key={execution.id} divider>
                      <ListItemIcon>
                        <PlayArrowIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={execution.prompt_data?.title || 'Untitled Prompt'}
                        secondary={`${new Date(execution.created_at).toLocaleString()} • ${execution.model}`}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Tooltip title="View details">
                          <IconButton
                            size="small"
                            component={Link}
                            to={`/history?execution=${execution.id}`}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Chip
                          label={execution.status === 'success' ? 'Success' : 'Error'}
                          color={execution.status === 'success' ? 'success' : 'error'}
                          size="small"
                        />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2 }}>
                  No recent activity
                </Typography>
              )}
            </CardContent>
            {stats.recentExecutions.length > 0 && (
              <CardActions>
                <Button 
                  size="small" 
                  component={Link} 
                  to="/history"
                >
                  View All Activity
                </Button>
              </CardActions>
            )}
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Popular Prompts</Typography>
              <Divider sx={{ mb: 2 }} />
              
              {stats.popularPrompts.length > 0 ? (
                <List>
                  {stats.popularPrompts.map(({ prompt, count }) => (
                    <ListItem key={prompt.id} divider>
                      <ListItemText
                        primary={prompt.title}
                        secondary={`${prompt.folder || 'Default'} • ${prompt.model}`}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Times executed">
                          <Chip 
                            label={`${count} ${count === 1 ? 'execution' : 'executions'}`}
                            size="small"
                            sx={{ mr: 1 }}
                          />
                        </Tooltip>
                        <Button 
                          size="small" 
                          variant="outlined"
                          component={Link}
                          to={`/editor/${prompt.id}`}
                        >
                          Use
                        </Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2 }}>
                  No prompts executed yet
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Folders Distribution */}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Prompts by Folder</Typography>
              <Divider sx={{ mb: 2 }} />
              
              <Grid container spacing={2}>
                {stats.promptsByFolder.map(({ folder, count }) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={folder}>
                    <Paper 
                      elevation={0} 
                      variant="outlined" 
                      sx={{ 
                        p: 2, 
                        textAlign: 'center',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <FolderIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
                      <Typography variant="subtitle1">{folder}</Typography>
                      <Typography variant="h6">{count}</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;