import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { blocksService } from '../../services/apiService';
import { useAuth } from '../../contexts/AuthContext';

const ApiBlockLibrary = ({ onSelectBlock, selectedBlocks = [], onBlockUpdate }) => {
  const { user } = useAuth();
  const [blocks, setBlocks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // New block form state
  const [openNewBlockDialog, setOpenNewBlockDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newBlock, setNewBlock] = useState({
    title: '',
    content: '',
    category: 'system_message',
    tags: []
  });
  const [newTag, setNewTag] = useState('');
  const [formErrors, setFormErrors] = useState({});
  
  // Load blocks from the database
  useEffect(() => {
    const loadBlocks = async () => {
      if (!user) return;

      try {
        setLoading(true);
        setError(null);

        console.log('Loading blocks for user:', user.id);
        console.log('User object:', user);

        // Try to get blocks
        const data = await blocksService.getBlocks(user.id);
        console.log('Loaded blocks:', data);

        // If no blocks exist, create a sample one
        if (data.length === 0) {
          try {
            console.log('No blocks found, creating a sample block');
            const sampleBlock = await blocksService.createBlock({
              user_id: user.id,
              title: 'Sample System Block',
              content: 'This is a sample system block to help you get started.',
              category: 'system',
              tags: ['sample', 'system'],
              is_public: false
            });

            console.log('Created sample block:', sampleBlock);
            setBlocks([sampleBlock]);
          } catch (createErr) {
            console.error('Error creating sample block:', createErr);
            setError('Failed to create a sample block. Please try again later.');
            setBlocks([]);
          }
        } else {
          setBlocks(data);
        }
      } catch (err) {
        console.error('Error loading blocks:', err);
        setError('Failed to load blocks. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadBlocks();
  }, [user]);

  const handleMenuOpen = (event, blockId) => {
    setAnchorEl(event.currentTarget);
    setSelectedBlockId(blockId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBlockId(null);
  };

  const handleDeleteBlock = async () => {
    try {
      // For mock blocks, just remove from state
      if (typeof selectedBlockId === 'string' && selectedBlockId.startsWith('mock-')) {
        setBlocks(blocks.filter(block => block.id !== selectedBlockId));
        setSnackbar({
          open: true,
          message: 'Block deleted successfully',
          severity: 'success'
        });
        handleMenuClose();
        return;
      }
      
      await blocksService.deleteBlock(selectedBlockId, user.id);
      setBlocks(blocks.filter(block => block.id !== selectedBlockId));
      
      setSnackbar({
        open: true,
        message: 'Block deleted successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error deleting block:', err);
      setSnackbar({
        open: true,
        message: `Error deleting block: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      handleMenuClose();
    }
  };

  const handleDuplicateBlock = async () => {
    try {
      const blockToDuplicate = blocks.find(b => b.id === selectedBlockId);
      if (!blockToDuplicate) return;
      
      // For mock blocks, just add a copy to state
      if (typeof selectedBlockId === 'string' && selectedBlockId.startsWith('mock-')) {
        const newMockBlock = {
          ...blockToDuplicate,
          id: `mock-${Date.now()}`,
          title: `${blockToDuplicate.title} (Copy)`
        };
        setBlocks([...blocks, newMockBlock]);
        setSnackbar({
          open: true,
          message: 'Block duplicated successfully',
          severity: 'success'
        });
        handleMenuClose();
        return;
      }
      
      // Create a new block based on the duplicated one
      const newBlock = await blocksService.createBlock({
        ...blockToDuplicate,
        title: `${blockToDuplicate.title} (Copy)`,
        user_id: user.id
      });
      
      setBlocks([...blocks, newBlock]);
      
      setSnackbar({
        open: true,
        message: 'Block duplicated successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error duplicating block:', err);
      setSnackbar({
        open: true,
        message: `Error duplicating block: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      handleMenuClose();
    }
  };

  const handleOpenNewBlockDialog = () => {
    setIsEditing(false);
    setNewBlock({
      title: '',
      content: '',
      category: 'system',
      tags: []
    });
    setOpenNewBlockDialog(true);
  };

  const handleOpenEditBlockDialog = (block) => {
    setIsEditing(true);
    setNewBlock({
      id: block.id,
      user_id: block.user_id,
      title: block.title,
      content: block.content,
      category: block.category,
      tags: block.tags || [],
      is_public: block.is_public
    });
    setOpenNewBlockDialog(true);
    handleMenuClose();
  };

  const handleCloseNewBlockDialog = () => {
    setOpenNewBlockDialog(false);
    setIsEditing(false);
    setNewBlock({
      title: '',
      content: '',
      category: 'system',
      tags: []
    });
    setNewTag('');
    setFormErrors({});
  };

  const handleNewBlockChange = (e) => {
    const { name, value } = e.target;
    setNewBlock({
      ...newBlock,
      [name]: value
    });
    
    // Clear error for this field if it exists
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null
      });
    }
  };

  const handleAddTag = () => {
    if (newTag && !newBlock.tags.includes(newTag)) {
      setNewBlock({
        ...newBlock,
        tags: [...newBlock.tags, newTag]
      });
      setNewTag('');
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setNewBlock({
      ...newBlock,
      tags: newBlock.tags.filter(tag => tag !== tagToDelete)
    });
  };

  const validateForm = () => {
    const errors = {};
    
    if (!newBlock.title.trim()) {
      errors.title = 'Title is required';
    }
    
    if (!newBlock.content.trim()) {
      errors.content = 'Content is required';
    }
    
    if (!newBlock.category) {
      errors.category = 'Category is required';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCreateBlock = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);

      // Create a mock block if we're in testing mode
      if (error) {
        const mockBlock = {
          ...newBlock,
          id: `mock-${Date.now()}`,
          user_id: user?.id || 'unknown'
        };
        setBlocks([mockBlock, ...blocks]);
        setSnackbar({
          open: true,
          message: 'Block created successfully (mock)',
          severity: 'success'
        });
        handleCloseNewBlockDialog();
        setLoading(false);
        return;
      }

      if (isEditing) {
        // Update existing block
        const updatedBlock = await blocksService.updateBlock(newBlock.id, {
          ...newBlock,
          user_id: user.id
        });

        // Update the blocks array
        setBlocks(blocks.map(block =>
          block.id === updatedBlock.id ? updatedBlock : block
        ));

        // Call the onBlockUpdate callback if provided
        if (onBlockUpdate) {
          onBlockUpdate(updatedBlock);
        }

        setSnackbar({
          open: true,
          message: 'Block updated successfully',
          severity: 'success'
        });
      } else {
        // Format content with a new line at the end if it doesn't have one
        const formattedContent = newBlock.content.endsWith('\n')
          ? newBlock.content
          : `${newBlock.content}\n`;

        // Create new block
        const createdBlock = await blocksService.createBlock({
          ...newBlock,
          content: formattedContent,
          user_id: user.id
        });

        setBlocks([createdBlock, ...blocks]);

        setSnackbar({
          open: true,
          message: 'Block created successfully',
          severity: 'success'
        });
      }

      handleCloseNewBlockDialog();
    } catch (err) {
      console.error(`Error ${isEditing ? 'updating' : 'creating'} block:`, err);
      setSnackbar({
        open: true,
        message: `Error ${isEditing ? 'updating' : 'creating'} block: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const filteredBlocks = blocks.filter(block => {
    const matchesSearch = 
      block.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
      block.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (block.tags && Array.isArray(block.tags) && block.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())));
    
    const matchesCategory = filterCategory === '' || block.category === filterCategory;
    
    return matchesSearch && matchesCategory;
  });

  const isBlockSelected = (blockId) => {
    return selectedBlocks.some(block => block.id === blockId);
  };

  // Render block cards
  const renderBlockCards = () => {
    return filteredBlocks.map((block) => (
      <Grid item xs={12} sm={6} md={4} key={block.id}>
        <Card 
          sx={{ 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
            border: isBlockSelected(block.id) ? 2 : 0,
            borderColor: 'primary.main'
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Typography variant="h6" component="div" gutterBottom>
                {block.title}
              </Typography>
              <IconButton 
                size="small"
                onClick={(e) => handleMenuOpen(e, block.id)}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
            
            <Chip
              label={block.category}
              size="small"
              color={
                block.category === 'system_message' ? 'primary' :
                block.category === 'user_message' ? 'success' :
                block.category === 'format' ? 'secondary' :
                'default'
              }
              sx={{ mb: 1 }}
            />
            
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                mb: 2,
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {block.content}
            </Typography>
            
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
              {block.tags && Array.isArray(block.tags) && block.tags.map((tag, index) => (
                <Chip 
                  key={index} 
                  label={tag} 
                  size="small" 
                  variant="outlined"
                />
              ))}
            </Box>
          </CardContent>
          <CardActions>
            {onSelectBlock && (
              <Button 
                size="small"
                onClick={() => onSelectBlock(block)}
                disabled={isBlockSelected(block.id)}
              >
                {isBlockSelected(block.id) ? 'Added' : 'Add to Prompt'}
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    ));
  };

  // Render content based on loading state and blocks availability
  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (filteredBlocks.length > 0) {
      return (
        <Grid container spacing={3}>
          {renderBlockCards()}
        </Grid>
      );
    }

    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h6" color="text.secondary">
          No blocks found matching your filters
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleOpenNewBlockDialog}
          sx={{ mt: 2 }}
        >
          Create New Block
        </Button>
      </Box>
    );
  };

  return (
    <Box>
      {error && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          {error} Using mock blocks for demonstration.
        </Alert>
      )}
      
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search blocks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Category</InputLabel>
              <Select
                value={filterCategory}
                label="Filter by Category"
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                <MenuItem value="">All Categories</MenuItem>
                <MenuItem value="system_message">System Message</MenuItem>
                <MenuItem value="user_message">User Message</MenuItem>
                <MenuItem value="format">Format</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenNewBlockDialog}
            >
              New Block
            </Button>
          </Grid>
        </Grid>
      </Box>
      
      {renderContent()}
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          const block = blocks.find(b => b.id === selectedBlockId);
          if (block) handleOpenEditBlockDialog(block);
        }}>Edit</MenuItem>
        <MenuItem onClick={handleDuplicateBlock}>Duplicate</MenuItem>
        <MenuItem onClick={handleDeleteBlock}>Delete</MenuItem>
      </Menu>
      
      {/* New/Edit Block Dialog */}
      <Dialog
        open={openNewBlockDialog}
        onClose={handleCloseNewBlockDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{isEditing ? 'Edit Block' : 'Create New Block'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Title"
            name="title"
            value={newBlock.title}
            onChange={handleNewBlockChange}
            margin="normal"
            error={!!formErrors.title}
            helperText={formErrors.title}
            required
          />
          
          <FormControl fullWidth margin="normal" error={!!formErrors.category}>
            <InputLabel>Category</InputLabel>
            <Select
              name="category"
              value={newBlock.category}
              label="Category"
              onChange={handleNewBlockChange}
              required
            >
              <MenuItem value="system_message">System Message</MenuItem>
              <MenuItem value="user_message">User Message</MenuItem>
              <MenuItem value="format">Format</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
            {formErrors.category && (
              <Typography variant="caption" color="error">
                {formErrors.category}
              </Typography>
            )}
          </FormControl>
          
          <TextField
            fullWidth
            label="Content"
            name="content"
            value={newBlock.content}
            onChange={handleNewBlockChange}
            margin="normal"
            multiline
            rows={6}
            error={!!formErrors.content}
            helperText={formErrors.content}
            required
          />
          
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Tags
            </Typography>
            <Box sx={{ display: 'flex', mb: 1 }}>
              <TextField
                size="small"
                label="Add tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                sx={{ mr: 1 }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={handleAddTag}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {newBlock.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(tag)}
                  size="small"
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewBlockDialog}>Cancel</Button>
          <Button
            onClick={handleCreateBlock}
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : isEditing ? 'Update Block' : 'Create Block'}
          </Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ApiBlockLibrary;