// Configuration settings for the application
const config = {
  // API URL - will use relative URL in production or environment variable if provided
  apiUrl: process.env.REACT_APP_API_URL || '',
  
  // Supabase configuration
  supabase: {
    url: process.env.REACT_APP_SUPABASE_URL || 'https://jslmqhujhfscofclcuur.supabase.co',
    key: process.env.REACT_APP_SUPABASE_ANON_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpzbG1xaHVqaGZzY29mY2xjdXVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDI2NDg2MTgsImV4cCI6MjA1ODIyNDYxOH0.-lmCqk3TFZu4Dxu_HeFNRnQDIf_YXzVfJo-d_Anux4w'
  },
  
  // Server port
  serverPort: process.env.PORT || 3001
};

export default config;