import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Divider,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardContent
} from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function Settings({ onThemeChange, currentTheme }) {
  const [settings, setSettings] = useState({
    apiKey: '••••••••••••••••••••••••••••••',
    defaultModel: 'gpt-4',
    theme: currentTheme || 'light',
    notifications: true,
    autoSave: true,
    folders: [
      'Creative Writing',
      'Work',
      'Programming',
      'Marketing'
    ]
  });

  const [newFolder, setNewFolder] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState('');

  // Update settings when theme changes from outside
  useEffect(() => {
    setSettings(prev => ({
      ...prev,
      theme: currentTheme
    }));
  }, [currentTheme]);

  const handleSettingChange = (e) => {
    const { name, value, checked } = e.target;

    // If theme is changing, also call the parent component's theme change handler
    if (name === 'theme' && value !== settings.theme) {
      onThemeChange();
    }

    setSettings({
      ...settings,
      [name]: name === 'notifications' || name === 'autoSave' ? checked : value
    });
  };

  const handleAddFolder = () => {
    if (newFolder && !settings.folders.includes(newFolder)) {
      setSettings({
        ...settings,
        folders: [...settings.folders, newFolder]
      });
      setNewFolder('');
    }
  };

  const handleDeleteFolderConfirm = (folder) => {
    setFolderToDelete(folder);
    setOpenDialog(true);
  };

  const handleDeleteFolder = () => {
    setSettings({
      ...settings,
      folders: settings.folders.filter(folder => folder !== folderToDelete)
    });
    setOpenDialog(false);
  };

  const handleSaveSettings = () => {
    // In a real app, this would save to a database or local storage
    console.log('Saving settings:', settings);
    setShowSuccess(true);
  };

  const handleCloseSnackbar = () => {
    setShowSuccess(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              AI Provider API Keys
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary" paragraph>
                Configure API keys for different AI providers to use their models in your prompts.
              </Typography>

              <Button
                variant="contained"
                component={Link}
                to="/settings/api-keys"
                sx={{ mt: 1 }}
              >
                Manage API Keys
              </Button>
            </Box>
          </Paper>

          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Default AI Provider
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <FormControl fullWidth margin="normal">
              <InputLabel>Default AI Provider</InputLabel>
              <Select
                name="defaultProvider"
                value={settings.defaultProvider || 'openai'}
                label="Default AI Provider"
                onChange={handleSettingChange}
              >
                <MenuItem value="openai">OpenAI</MenuItem>
                <MenuItem value="anthropic">Anthropic</MenuItem>
                <MenuItem value="google">Google AI</MenuItem>
                <MenuItem value="mistral">Mistral AI</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Default Model</InputLabel>
              <Select
                name="defaultModel"
                value={settings.defaultModel}
                label="Default Model"
                onChange={handleSettingChange}
              >
                <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
                <MenuItem value="gpt-4">GPT-4</MenuItem>
                <MenuItem value="claude-2">Claude 2</MenuItem>
                <MenuItem value="gemini-pro">Gemini Pro</MenuItem>
              </Select>
            </FormControl>
          </Paper>
          
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Application Settings
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {settings.theme === 'dark' ? (
                      <Brightness7Icon sx={{ mr: 2, color: 'primary.main' }} />
                    ) : (
                      <Brightness4Icon sx={{ mr: 2, color: 'primary.main' }} />
                    )}
                    <Box>
                      <Typography variant="h6">
                        {settings.theme === 'dark' ? 'Dark Mode' : 'Light Mode'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {settings.theme === 'dark'
                          ? 'Easier on the eyes in low-light environments'
                          : 'Classic look with light background'}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onThemeChange();
                      setSettings({
                        ...settings,
                        theme: settings.theme === 'light' ? 'dark' : 'light'
                      });
                    }}
                  >
                    Switch to {settings.theme === 'light' ? 'Dark' : 'Light'}
                  </Button>
                </Box>
              </CardContent>
            </Card>

            <FormControl fullWidth margin="normal">
              <InputLabel>Theme Preference</InputLabel>
              <Select
                name="theme"
                value={settings.theme}
                label="Theme Preference"
                onChange={handleSettingChange}
              >
                <MenuItem value="light">Light</MenuItem>
                <MenuItem value="dark">Dark</MenuItem>
                <MenuItem value="system">System Default</MenuItem>
              </Select>
            </FormControl>
            
            <FormControlLabel
              control={
                <Switch
                  checked={settings.notifications}
                  onChange={handleSettingChange}
                  name="notifications"
                  color="primary"
                />
              }
              label="Enable Notifications"
              sx={{ mt: 2, display: 'block' }}
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={settings.autoSave}
                  onChange={handleSettingChange}
                  name="autoSave"
                  color="primary"
                />
              }
              label="Auto-save Prompts"
              sx={{ display: 'block' }}
            />
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Folder Management
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <Box sx={{ display: 'flex', mb: 2 }}>
              <TextField
                fullWidth
                label="New Folder Name"
                value={newFolder}
                onChange={(e) => setNewFolder(e.target.value)}
                sx={{ mr: 1 }}
              />
              <Button 
                variant="outlined" 
                startIcon={<AddIcon />}
                onClick={handleAddFolder}
              >
                Add
              </Button>
            </Box>
            
            <List>
              {settings.folders.map((folder, index) => (
                <ListItem key={index} divider={index < settings.folders.length - 1}>
                  <ListItemText primary={folder} />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      aria-label="delete"
                      onClick={() => handleDeleteFolderConfirm(folder)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {settings.folders.length === 0 && (
                <ListItem>
                  <ListItemText 
                    primary="No folders created yet" 
                    secondary="Add folders to organize your prompts"
                  />
                </ListItem>
              )}
            </List>
          </Paper>
          
          <Box sx={{ mt: 3 }}>
            <Alert severity="info" sx={{ mb: 3 }}>
              Changes to settings will be applied immediately after saving.
            </Alert>
            
            <Button 
              variant="contained" 
              startIcon={<SaveIcon />}
              onClick={handleSaveSettings}
              fullWidth
            >
              Save Settings
            </Button>
          </Box>
        </Grid>
      </Grid>
      
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Settings saved successfully!
        </Alert>
      </Snackbar>
      
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Delete Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the folder "{folderToDelete}"? 
            This won't delete any prompts inside the folder.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteFolder} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Settings;