import React from 'react';
import { Box, Typography, Paper, Alert } from '@mui/material';
import ApiBlockLibrary from '../components/blocks/ApiBlockLibrary.jsx';
import { useAuth } from '../contexts/AuthContext';

function BlocksLibrary() {
  const { user } = useAuth();
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Prompt Blocks Library
      </Typography>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="body1" paragraph>
          Prompt blocks are reusable components that can be shared across multiple prompts.
          Create and manage your blocks here to build more consistent and effective prompts.
        </Typography>

        {!user ? (
          <Alert severity="info">
            Please log in to manage your prompt blocks.
          </Alert>
        ) : (
          <ApiBlockLibrary />
        )}
      </Paper>
    </Box>
  );
}

export default BlocksLibrary;