import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  IconButton,
  Divider,
  Chip,
  Tooltip,
  Paper,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

/**
 * A component that allows building prompts by combining text sections and blocks
 */
const PromptModuleBuilder = ({
  blocks = [],
  content = '',
  onContentChange,
  onBlocksChange,
  onRemoveBlock,
  onMoveBlock,
  onAddBlock,
  onOpenBlockLibrary
}) => {
  // Split content into sections based on block positions
  const [sections, setSections] = useState([]);
  const [draggedSection, setDraggedSection] = useState(null);
  const [dragOverSection, setDragOverSection] = useState(null);
  const [addMenuAnchor, setAddMenuAnchor] = useState(null);

  // Parse the content and blocks to create sections
  useEffect(() => {
    // Ensure blocks is an array
    const safeBlocks = Array.isArray(blocks) ? blocks : [];

    // Skip this effect if we're just updating content from a text field
    // This is crucial to prevent focus loss
    // But don't skip if blocks have changed (their content might have been updated)
    const blockSections = sections.filter(s => s.type === 'block');
    const blocksChanged = safeBlocks.length !== blockSections.length ||
      safeBlocks.some((block, index) => {
        const blockSection = blockSections[index];
        return !blockSection ||
               !block ||
               blockSection.block.id !== block.id ||
               blockSection.block.content !== block.content;
      });

    if (sections.length > 0 &&
        safeBlocks.length === blockSections.length &&
        sections.some(s => s.type === 'text') &&
        !blocksChanged) {
      return;
    }

    const parseContent = () => {
      // Ensure blocks is an array
      const safeBlocks = Array.isArray(blocks) ? blocks : [];
      console.log("Parsing content:", content, "Blocks:", safeBlocks.length);

      // If there are no blocks and no content, create an empty text section
      if (safeBlocks.length === 0 && !content.trim()) {
        console.log("Creating empty text section");
        const newSectionId = `text-${Date.now()}`;
        setSections([{
          type: 'text',
          content: '',
          id: newSectionId
        }]);
        // Set focus to this new section
        newTextFieldRef.current = newSectionId;
        return;
      }

      // If there are no blocks but there is content, return the content as a single section
      if (safeBlocks.length === 0) {
        console.log("Creating text section with content");
        setSections([{ type: 'text', content, id: 'text-0' }]);
        return;
      }

      // Create a temporary representation of the full prompt
      let fullPrompt = content;
      let newSections = [];

      // Extract text sections and blocks
      let remainingText = fullPrompt;

      safeBlocks.forEach((block, index) => {
        // Ensure block has a content property
        if (!block || typeof block.content !== 'string') {
          console.error('Invalid block found:', block);
          return; // Skip this block
        }

        const blockPosition = remainingText.indexOf(block.content);

        if (blockPosition !== -1) {
          // Add text before the block if there is any
          if (blockPosition > 0) {
            newSections.push({
              type: 'text',
              content: remainingText.substring(0, blockPosition).trim(),
              id: `text-${newSections.length}`
            });
          }

          // Add the block
          newSections.push({
            type: 'block',
            block,
            id: `block-${block.id || `temp-${Date.now()}-${index}`}`
          });

          // Update remaining text
          remainingText = remainingText.substring(blockPosition + block.content.length);
        } else {
          // If block not found in content, add it at the end
          if (index === 0) {
            newSections.push({
              type: 'text',
              content: remainingText.trim(),
              id: `text-${newSections.length}`
            });
            newSections.push({
              type: 'block',
              block,
              id: `block-${block.id || `temp-${Date.now()}-${index}`}`
            });
            remainingText = '';
          } else {
            newSections.push({
              type: 'block',
              block,
              id: `block-${block.id || `temp-${Date.now()}-${index}`}`
            });
          }
        }
      });

      // Add any remaining text
      if (remainingText.trim()) {
        newSections.push({
          type: 'text',
          content: remainingText.trim(),
          id: `text-${newSections.length}`
        });
      }

      // We no longer automatically add an empty text section at the end
      // This allows users to decide whether to add a message or not

      setSections(newSections);
    };

    parseContent();
  }, [blocks, content]);

  // Keep track of the active section to maintain focus
  const [activeSectionId, setActiveSectionId] = useState(null);

  // Update the content when sections change
  const handleSectionChange = (index, newContent) => {
    // Store the current section ID to maintain focus
    const currentSectionId = sections[index].id;
    setActiveSectionId(currentSectionId);

    const newSections = [...sections];
    newSections[index].content = newContent;

    // Update the sections state directly first
    setSections(newSections);

    // Then update the content without triggering a full rebuild
    let newFullContent = '';
    newSections.forEach((section, idx) => {
      if (section.type === 'text') {
        const textContent = section.content.trim() === '' ? ' ' : section.content;
        newFullContent += textContent;
      } else {
        newFullContent += section.block.content;
      }

      if (idx < newSections.length - 1) {
        newFullContent += '\n\n';
      }
    });

    // Update the content if callback is provided
    if (typeof onContentChange === 'function') {
      onContentChange(newFullContent);
    }
  };

  // Force a refresh of the sections from the content
  const refreshSections = () => {
    // Only proceed if callback is provided
    if (typeof onContentChange !== 'function') return;

    // This will trigger the useEffect to re-parse the content
    const currentContent = content;
    onContentChange(currentContent + ' ');
    setTimeout(() => onContentChange(currentContent), 10);
  };

  // Rebuild the full content from sections
  const rebuildContent = (sectionsToUse) => {
    let newFullContent = '';
    sectionsToUse.forEach((section, index) => {
      if (section.type === 'text') {
        // Make sure empty text sections have at least a space
        const textContent = section.content.trim() === '' ? ' ' : section.content;
        newFullContent += textContent;
      } else {
        newFullContent += section.block.content;
      }

      // Add newlines between sections, but not after the last one
      if (index < sectionsToUse.length - 1) {
        newFullContent += '\n\n';
      }
    });

    // Update the content if callback is provided
    if (typeof onContentChange === 'function') {
      onContentChange(newFullContent);
    }

    // Also update the sections state to ensure UI is in sync
    setSections(sectionsToUse);

    // Force a re-render after a short delay to ensure focus is maintained
    // This is necessary because the content change might trigger a re-parse
    setTimeout(() => {
      // Just setting the same active section ID will trigger the inputRef callback
      setActiveSectionId(activeSectionId);
    }, 10);
  };

  // Move a section up or down
  const handleMoveSection = (index, direction) => {
    if (
      (direction === 'up' && index === 0) ||
      (direction === 'down' && index === sections.length - 1)
    ) {
      return;
    }

    const newSections = [...sections];
    const newIndex = direction === 'up' ? index - 1 : index + 1;

    // Swap sections
    [newSections[index], newSections[newIndex]] = [newSections[newIndex], newSections[index]];

    // Update the blocks array if callback is provided
    const newBlocks = newSections
      .filter(section => section.type === 'block')
      .map(section => section.block);

    if (typeof onBlocksChange === 'function') {
      onBlocksChange(newBlocks);
    }

    // Rebuild the content and update the UI
    rebuildContent(newSections);
  };

  // Handle drag and drop functionality
  const handleDragStart = (e, index) => {
    setDraggedSection(index);
    e.dataTransfer.effectAllowed = 'move';
    // Required for Firefox
    e.dataTransfer.setData('text/plain', index.toString());
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedSection === null) return;
    if (draggedSection === index) return;

    setDragOverSection(index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    if (draggedSection === null || draggedSection === index) return;

    const newSections = [...sections];
    const draggedItem = newSections[draggedSection];

    // Remove the dragged item
    newSections.splice(draggedSection, 1);

    // Insert at the new position
    newSections.splice(index, 0, draggedItem);

    // Update the blocks array if callback is provided
    const newBlocks = newSections
      .filter(section => section.type === 'block')
      .map(section => section.block);

    if (typeof onBlocksChange === 'function') {
      onBlocksChange(newBlocks);
    }

    // Rebuild the content and update the UI
    rebuildContent(newSections);

    // Reset drag state
    setDraggedSection(null);
    setDragOverSection(null);
  };

  const handleDragEnd = () => {
    setDraggedSection(null);
    setDragOverSection(null);
  };

  // Handle deleting a text section
  const handleDeleteSection = (index) => {
    // Don't allow deleting if there's only one section
    if (sections.length <= 1) return;

    // Don't allow deleting if it's the only text section and there are no blocks
    const textSections = sections.filter(section => section.type === 'text');
    const blockSections = sections.filter(section => section.type === 'block');

    // Only prevent deletion if it's the only text section AND there are no blocks
    if (textSections.length <= 1 && sections[index].type === 'text' && blockSections.length === 0) return;

    const newSections = [...sections];

    // Remove the section
    newSections.splice(index, 1);

    // Only add an empty text section if there are no sections left at all
    if (newSections.length === 0) {
      newSections.push({
        type: 'text',
        content: '',
        id: `text-${Date.now()}`
      });
    }

    // Rebuild the content
    rebuildContent(newSections);
  };

  // Handle the Add menu
  const handleAddClick = (event) => {
    setAddMenuAnchor(event.currentTarget);
  };

  const handleAddClose = () => {
    setAddMenuAnchor(null);
  };

  // Create a ref to track the newly added text field
  const newTextFieldRef = useRef(null);

  const handleAddMessage = () => {
    // Create a new sections array with an additional empty text section
    const newSections = [...sections];

    // Add a new text section at the end with an empty string
    const newSectionId = `text-${Date.now()}`;
    newSections.push({
      type: 'text',
      content: '',
      id: newSectionId
    });

    // Update the sections state directly
    setSections(newSections);

    // Build the content from the sections
    let newFullContent = '';
    newSections.forEach((section, index) => {
      if (section.type === 'text') {
        const textContent = section.content.trim() === '' ? ' ' : section.content;
        newFullContent += textContent;
      } else {
        newFullContent += section.block.content;
      }

      // Add newlines between sections
      if (index < newSections.length - 1) {
        newFullContent += '\n\n';
      }
    });

    // Update the content if callback is provided
    if (typeof onContentChange === 'function') {
      onContentChange(newFullContent);
    }

    // Close the menu
    handleAddClose();

    // Set a flag to focus the new text field after render
    newTextFieldRef.current = newSectionId;
  };

  const handleAddBlockClick = () => {
    // Use onOpenBlockLibrary if provided, otherwise fall back to onAddBlock
    if (typeof onOpenBlockLibrary === 'function') {
      onOpenBlockLibrary();
    } else if (typeof onAddBlock === 'function') {
      onAddBlock();
    }
    handleAddClose();

    // We no longer force a refresh that would add an empty message box
  };
  
  return (
    <Box>
      {/* We always have at least one section now */}
      {sections.map((section, index) => (
            <Box
              key={section.id}
              sx={{
                mb: 2,
                border: dragOverSection === index ? '2px dashed' : '2px solid transparent',
                borderColor: 'primary.main',
                borderRadius: 1,
                transition: 'border 0.2s'
              }}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={handleDragEnd}
              onDrop={(e) => handleDrop(e, index)}
            >
              {section.type === 'text' ? (
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={section.content}
                    onChange={(e) => {
                      // Use a direct approach to update the content without losing focus
                      const newValue = e.target.value;

                      // Update the section content directly
                      const newSections = [...sections];
                      newSections[index].content = newValue;

                      // Update the sections state
                      setSections(newSections);

                      // Update the full content
                      let newFullContent = '';
                      newSections.forEach((s, i) => {
                        if (s.type === 'text') {
                          const textContent = s.content.trim() === '' ? ' ' : s.content;
                          newFullContent += textContent;
                        } else {
                          newFullContent += s.block.content;
                        }

                        if (i < newSections.length - 1) {
                          newFullContent += '\n\n';
                        }
                      });

                      // Update the content without triggering a full rebuild if callback is provided
                      if (typeof onContentChange === 'function') {
                        onContentChange(newFullContent);
                      }
                    }}
                    placeholder="Write your message here..."
                    variant="outlined"
                    size="small"
                    autoFocus={newTextFieldRef.current === section.id}
                    onFocus={() => {
                      // Clear the ref once focused
                      if (newTextFieldRef.current === section.id) {
                        newTextFieldRef.current = null;
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          position: 'absolute',
                          left: 8,
                          top: 8,
                          cursor: 'move',
                          opacity: 0.5,
                          '&:hover': { opacity: 1 }
                        }}>
                          <DragIndicatorIcon color="action" fontSize="small" />
                        </Box>
                      ),
                      sx: { pl: 4 }
                    }}
                  />
                  <Box sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    display: 'flex',
                    opacity: 0.7,
                    '&:hover': { opacity: 1 }
                  }}>
                    <Tooltip title="Move up">
                      <IconButton
                        size="small"
                        onClick={() => handleMoveSection(index, 'up')}
                        disabled={index === 0}
                      >
                        <ArrowUpwardIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Move down">
                      <IconButton
                        size="small"
                        onClick={() => handleMoveSection(index, 'down')}
                        disabled={index === sections.length - 1}
                      >
                        <ArrowDownwardIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete message">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleDeleteSection(index)}
                        disabled={sections.length <= 1 || (sections.filter(s => s.type === 'text').length <= 1 && section.type === 'text' && sections.filter(s => s.type === 'block').length === 0)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <Card variant="outlined" sx={{ bgcolor: 'background.default' }}>
                  <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <DragIndicatorIcon color="action" sx={{ mr: 1, opacity: 0.5, cursor: 'move' }} />
                        <Typography variant="subtitle2" color="primary">
                          {section.block.title || 'Block'}
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title="Move up">
                          <IconButton
                            size="small"
                            onClick={() => handleMoveSection(index, 'up')}
                            disabled={index === 0}
                          >
                            <ArrowUpwardIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Move down">
                          <IconButton
                            size="small"
                            onClick={() => handleMoveSection(index, 'down')}
                            disabled={index === sections.length - 1}
                          >
                            <ArrowDownwardIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove block">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => onRemoveBlock(section.block.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      <Chip
                        label={section.block.category || 'General'}
                        size="small"
                        variant="outlined"
                        color="primary"
                      />
                      {section.block.tags && section.block.tags.map((tag, i) => (
                        <Chip
                          key={i}
                          label={tag}
                          size="small"
                          variant="outlined"
                        />
                      ))}
                    </Box>

                    <Tooltip title="Click to view full content">
                      <Paper
                        variant="outlined"
                        sx={{
                          mt: 1,
                          p: 1,
                          maxHeight: '80px',
                          overflow: 'hidden',
                          position: 'relative',
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '30px',
                            background: 'linear-gradient(transparent, rgba(255,255,255,0.8))'
                          }
                        }}
                      >
                        <Typography variant="body2" sx={{ fontSize: '0.8rem', whiteSpace: 'pre-line' }}>
                          {section.block.content}
                        </Typography>
                      </Paper>
                    </Tooltip>
                  </CardContent>
                </Card>
              )}
            </Box>
          ))}

          {/* Add button with dropdown menu */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
              size="small"
            >
              Add
            </Button>
          </Box>

      {/* Menu for Add button */}
      <Menu
        anchorEl={addMenuAnchor}
        open={Boolean(addMenuAnchor)}
        onClose={handleAddClose}
      >
        <MenuItem onClick={handleAddMessage}>
          <ListItemIcon>
            <TextFieldsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Message</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAddBlockClick}>
          <ListItemIcon>
            <LibraryBooksIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Block</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default PromptModuleBuilder;