import { supabase } from './supabase';

// Table name
const TABLE_NAME = 'user_settings';

/**
 * Get user settings
 * @param {string} userId - The user ID
 * @returns {Promise<Object>} - The user settings
 */
export const getUserSettings = async (userId) => {
  try {
    console.log('Getting settings for user:', userId);

    // Use maybeSingle instead of single to avoid PGRST116 error
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .select('*')
      .eq('user_id', userId)
      .maybeSingle();

    if (error) {
      console.error('Error fetching user settings:', error);
      throw error;
    }

    // Return default settings if none exist
    if (!data) {
      console.log('No settings found, returning defaults');
      const defaultSettings = {
        user_id: userId,
        theme: 'light',
        default_provider: 'openai',
        default_model: 'gpt-4',
        notifications_enabled: true,
        auto_save_enabled: true,
        folders: ['Default', 'Work', 'Personal', 'Creative']
      };

      // Create default settings in the database
      try {
        console.log('Creating default settings for user:', userId);
        const { data: createdData, error: createError } = await supabase
          .from(TABLE_NAME)
          .insert([defaultSettings])
          .select()
          .single();

        if (createError) {
          console.error('Error creating default settings:', createError);
          // Return default settings even if creation fails
          return defaultSettings;
        }

        console.log('Default settings created successfully:', createdData);
        return createdData;
      } catch (createErr) {
        console.error('Exception creating default settings:', createErr);
        return defaultSettings;
      }
    }

    console.log('Settings found:', data);
    return data;
  } catch (err) {
    console.error('Exception in getUserSettings:', err);
    // Return default settings in case of any error
    return {
      user_id: userId,
      theme: 'light',
      default_provider: 'openai',
      default_model: 'gpt-4',
      notifications_enabled: true,
      auto_save_enabled: true,
      folders: ['Default', 'Work', 'Personal', 'Creative']
    };
  }
};

/**
 * Update user settings
 * @param {string} userId - The user ID
 * @param {Object} settings - The settings to update
 * @returns {Promise<Object>} - The updated settings
 */
export const updateUserSettings = async (userId, settings) => {
  try {
    console.log('Updating settings for user:', userId, settings);

    // Check if settings exist for this user
    const { data: existingSettings, error: checkError } = await supabase
      .from(TABLE_NAME)
      .select('*')
      .eq('user_id', userId)
      .maybeSingle();

    if (checkError) {
      console.error('Error checking existing settings:', checkError);
      throw checkError;
    }

    if (existingSettings) {
      console.log('Existing settings found, updating');
      // Update existing settings
      const { data, error } = await supabase
        .from(TABLE_NAME)
        .update(settings)
        .eq('user_id', userId)
        .select()
        .maybeSingle();

      if (error) {
        console.error('Error updating user settings:', error);
        throw error;
      }

      console.log('Settings updated successfully:', data);
      return data;
    } else {
      console.log('No existing settings found, creating new settings');
      // Create new settings
      const fullSettings = {
        user_id: userId,
        theme: 'light',
        default_provider: 'openai',
        default_model: 'gpt-4',
        notifications_enabled: true,
        auto_save_enabled: true,
        folders: ['Default', 'Work', 'Personal', 'Creative'],
        ...settings
      };

      const { data, error } = await supabase
        .from(TABLE_NAME)
        .insert([fullSettings])
        .select()
        .maybeSingle();

      if (error) {
        console.error('Error creating user settings:', error);
        throw error;
      }

      console.log('New settings created successfully:', data);
      return data;
    }
  } catch (err) {
    console.error('Exception in updateUserSettings:', err);
    throw err;
  }
};

/**
 * Get user folders
 * @param {string} userId - The user ID
 * @returns {Promise<Array>} - Array of folder names
 */
export const getUserFolders = async (userId) => {
  const settings = await getUserSettings(userId);
  return settings.folders || ['Default'];
};

/**
 * Update user folders
 * @param {string} userId - The user ID
 * @param {Array} folders - Array of folder names
 * @returns {Promise<Object>} - The updated settings
 */
export const updateUserFolders = async (userId, folders) => {
  return updateUserSettings(userId, { folders });
};