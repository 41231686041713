import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, Container } from '@mui/material';
import { supabase } from '../services/supabase';

function AuthCallback() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    // Handle the OAuth callback
    const handleAuthCallback = async () => {
      try {
        // The hash contains the access token and other OAuth data
        const { data, error } = await supabase.auth.getSession();
        
        if (error) {
          throw error;
        }

        if (data?.session) {
          // Successfully authenticated, redirect to home page
          navigate('/');
        } else {
          // No session found, redirect to login
          navigate('/login');
        }
      } catch (err) {
        console.error('Error during auth callback:', err);
        setError(err.message || 'Authentication failed');
        // Redirect to login after a short delay
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        {error ? (
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
        ) : (
          <>
            <CircularProgress size={60} sx={{ mb: 4 }} />
            <Typography variant="h6" gutterBottom>
              Completing authentication...
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
}

export default AuthCallback;