import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline, CircularProgress } from '@mui/material';
import LoadingOverlay from './components/common/LoadingOverlay';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './components/layout/Header';
import Sidebar from './components/layout/Sidebar';
import Dashboard from './components/dashboard/Dashboard';
import PromptEditor from './pages/PromptEditor';
import PromptLibrary from './pages/PromptLibrary';
import BlocksLibrary from './pages/BlocksLibrary';
import History from './pages/History';
import Settings from './pages/Settings';
import ApiKeySettings from './pages/ApiKeySettings';
import Login from './pages/Login';
import Register from './pages/Register';
import AuthCallback from './pages/AuthCallback';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SidebarProvider, useSidebar } from './contexts/SidebarContext';
import { getUserSettings } from './services/settingsService';
import './App.css';

// Protected route component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

function AppContent() {
  const { user } = useAuth();
  const { sidebarOpen, toggleSidebar } = useSidebar();
  const [mode, setMode] = useState('light');
  const [loading, setLoading] = useState(true);

  // Load user settings
  useEffect(() => {
    const loadUserSettings = async () => {
      if (user) {
        try {
          const settings = await getUserSettings(user.id);
          setMode(settings.theme || 'light');
        } catch (error) {
          console.error('Error loading user settings:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    loadUserSettings();
  }, [user]);

  // Create a theme instance
  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#6200ea',
      },
      secondary: {
        main: '#03dac6',
      },
      background: {
        default: mode === 'light' ? '#f5f5f5' : '#121212',
        paper: mode === 'light' ? '#ffffff' : '#1e1e1e',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            textTransform: 'none',
            fontWeight: 500,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
          },
        },
      },
    },
  });

  const toggleThemeMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // We're now using the toggleSidebar from the SidebarContext

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingOverlay />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/*" element={
            <ProtectedRoute>
              <Box sx={{ display: 'flex', height: '100vh' }}>
                <Header toggleSidebar={toggleSidebar} toggleThemeMode={toggleThemeMode} mode={mode} />
                <Sidebar open={sidebarOpen} />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    p: 3,
                    mt: 8,
                    ml: sidebarOpen ? '240px' : 0,
                    transition: 'margin 0.2s',
                    backgroundColor: 'background.default',
                    minHeight: '100vh',
                    overflow: 'auto',
                  }}
                >
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/editor" element={<PromptEditor />} />
                    <Route path="/editor/:promptId" element={<PromptEditor />} />
                    <Route path="/library" element={<PromptLibrary />} />
                    <Route path="/blocks" element={<BlocksLibrary />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/settings" element={<Settings onThemeChange={toggleThemeMode} currentTheme={mode} />} />
                    <Route path="/settings/api-keys" element={<ApiKeySettings />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Box>
              </Box>
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <SidebarProvider>
        <AppContent />
      </SidebarProvider>
    </AuthProvider>
  );
}

export default App;