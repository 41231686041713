import React, { useState, useEffect } from 'react';
import { useSidebar } from '../../contexts/SidebarContext';
import { useAuth } from '../../contexts/AuthContext';
import { promptExecutionsService, promptsService } from '../../services/apiService';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Divider,
  Chip,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Autocomplete,
  InputAdornment
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ExecutionHistory from './ExecutionHistory';
import BlockLibrary from '../blocks/BlockLibrary';
import ApiBlockLibrary from '../blocks/ApiBlockLibrary.jsx';
import PromptModuleBuilder from './PromptModuleBuilder';

// Function to format code blocks with language indicators
const formatCodeWithLanguage = (text) => {
  if (!text) return '';

  // First, detect and replace code blocks with language indicators
  const codeBlockRegex = /```(\w*)\n([\s\S]*?)```/g;
  let processedText = text.replace(codeBlockRegex, (match, language, code) => {
    const displayLanguage = language || 'plaintext';
    return `<div class="code-block">
              <div class="language-indicator">
                <span>${displayLanguage}</span>
              </div>
              <div class="code-content">${code}</div>
            </div>`;
  });

  // Configure marked for other markdown elements
  marked.setOptions({
    breaks: true,
    gfm: true,
    headerIds: true,
    mangle: false
  });

  // Convert markdown to HTML
  const html = marked(processedText);

  // Sanitize HTML to prevent XSS attacks
  return DOMPurify.sanitize(html, {
    ADD_TAGS: ['div', 'span'],
    ADD_ATTR: ['class']
  });
};

const PromptFormNew = ({ 
  initialData, 
  onSave, 
  onTest, 
  loading, 
  aiResponse, 
  onCopyResponse, 
  responseRef,
  promptId,
  isNewPrompt
}) => {
  // Default data for a new prompt
  const defaultData = {
    title: '',
    description: '',
    content: '',
    provider: 'openai',
    model: 'gpt-4',
    tags: [],
    variables: [],
    blocks: [],
    is_favorite: false
  };

  // Ensure arrays are initialized
  const ensureArrays = (data) => {
    if (!data) return defaultData;
    return {
      ...data,
      tags: Array.isArray(data.tags) ? data.tags : [],
      variables: Array.isArray(data.variables) ? data.variables : [],
      blocks: Array.isArray(data.blocks) ? data.blocks : []
    };
  };

  const { sidebarOpen } = useSidebar();
  const { user } = useAuth();
  const [promptData, setPromptData] = useState(ensureArrays(initialData || defaultData));
  const [newTag, setNewTag] = useState('');
  const [newVariable, setNewVariable] = useState({ name: '', description: '', example: '' });
  const [errors, setErrors] = useState({});
  const [openBlocksDialog, setOpenBlocksDialog] = useState(false);
  const [addMenuAnchor, setAddMenuAnchor] = useState(null);
  const [availableTags, setAvailableTags] = useState([]);

  // Fetch all available tags from existing prompts
  useEffect(() => {
    const fetchTags = async () => {
      if (!user) return;

      try {
        const prompts = await promptsService.getPrompts(user.id);
        const allTags = prompts.reduce((acc, prompt) => {
          if (prompt.tags && Array.isArray(prompt.tags)) {
            prompt.tags.forEach(tag => acc.add(tag));
          }
          return acc;
        }, new Set());

        setAvailableTags([...allTags].sort());
      } catch (err) {
        console.error('Error fetching tags:', err);
      }
    };

    fetchTags();
  }, [user]);

  // Tab states for the three sections
  const [detailsTab, setDetailsTab] = useState(0);
  const [builderTab, setBuilderTab] = useState(0);
  const [resultsTab, setResultsTab] = useState(0);
  const [historyKey, setHistoryKey] = useState(Date.now());

  // Mock data for available providers and models
  const providers = [
    {
      id: 'openai',
      name: 'OpenAI',
      models: ['gpt-4o','gpt-3.5-turbo', 'gpt-4', 'gpt-4-turbo']
    },
    {
      id: 'anthropic',
      name: 'Anthropic',
      models: ['claude-3-opus', 'claude-3-sonnet', 'claude-3-haiku']
    },
    {
      id: 'google',
      name: 'Google',
      models: ['gemini-pro', 'gemini-ultra']
    }
  ];

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPromptData({
      ...promptData,
      [name]: value
    });

    // Clear error for this field if it exists
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  // Handle content changes from the prompt builder
  const handleContentChange = (newContent) => {
    setPromptData({
      ...promptData,
      content: newContent
    });
  };

  // Handle blocks changes from the prompt builder
  const handleBlocksChange = (newBlocks) => {
    setPromptData({
      ...promptData,
      blocks: newBlocks
    });
  };

  // Handle adding a tag
  const handleAddTag = () => {
    if (!newTag.trim()) return;
    if (promptData.tags.includes(newTag.trim())) return;

    setPromptData({
      ...promptData,
      tags: [...promptData.tags, newTag.trim()]
    });
    setNewTag('');
  };

  // Handle deleting a tag
  const handleDeleteTag = (tagToDelete) => {
    setPromptData({
      ...promptData,
      tags: promptData.tags.filter(tag => tag !== tagToDelete)
    });
  };

  // Handle variable form changes
  const handleVariableChange = (e) => {
    const { name, value } = e.target;
    setNewVariable({
      ...newVariable,
      [name]: value
    });
  };

  // Handle adding a variable
  const handleAddVariable = () => {
    if (!newVariable.name.trim()) return;
    
    // Check if variable name already exists
    if (promptData.variables.some(v => v.name === newVariable.name.trim())) {
      return;
    }

    setPromptData({
      ...promptData,
      variables: [...promptData.variables, {
        name: newVariable.name.trim(),
        description: newVariable.description.trim(),
        example: newVariable.example.trim()
      }]
    });
    setNewVariable({ name: '', description: '', example: '' });
  };

  // Handle deleting a variable
  const handleDeleteVariable = (index) => {
    const newVariables = [...promptData.variables];
    newVariables.splice(index, 1);
    setPromptData({
      ...promptData,
      variables: newVariables
    });
  };

  // Handle opening the blocks dialog
  const handleOpenBlocksDialog = () => {
    setOpenBlocksDialog(true);
  };

  // Handle closing the blocks dialog
  const handleCloseBlocksDialog = () => {
    setOpenBlocksDialog(false);
  };

  // Handle selecting a block from the library
  const handleSelectBlock = (block) => {
    // Check if block is already included
    if (promptData.blocks.some(b => b.id === block.id)) {
      return;
    }

    // Add the block to the prompt
    const newBlocks = [...promptData.blocks, block];
    
    // Update the content to include the block
    let newContent = promptData.content;
    if (newContent) {
      newContent = newContent.trim() + '\n\n' + block.content;
    } else {
      newContent = block.content;
    }

    setPromptData({
      ...promptData,
      blocks: newBlocks,
      content: newContent
    });
  };

  // Handle removing a block
  const handleRemoveBlock = (blockId) => {
    // Find the block to remove
    const blockToRemove = promptData.blocks.find(block => block.id === blockId);
    if (!blockToRemove) return;

    // Remove the block from the blocks array
    const newBlocks = promptData.blocks.filter(block => block.id !== blockId);
    
    // Remove the block content from the prompt content
    let newContent = promptData.content;
    if (blockToRemove.content) {
      newContent = newContent.replace(blockToRemove.content, '');
      // Clean up any extra newlines
      newContent = newContent.replace(/\n{3,}/g, '\n\n').trim();
    }

    setPromptData({
      ...promptData,
      blocks: newBlocks,
      content: newContent
    });
  };

  // Handle moving a block
  const handleMoveBlock = (blockId, direction) => {
    const blockIndex = promptData.blocks.findIndex(block => block.id === blockId);
    if (blockIndex === -1) return;

    const newIndex = direction === 'up' ? blockIndex - 1 : blockIndex + 1;
    if (newIndex < 0 || newIndex >= promptData.blocks.length) return;

    const newBlocks = [...promptData.blocks];
    [newBlocks[blockIndex], newBlocks[newIndex]] = [newBlocks[newIndex], newBlocks[blockIndex]];

    // Rebuild the content from scratch to ensure proper ordering
    let newContent = '';
    
    // If we have text content outside of blocks, we need to preserve it
    if (promptData.content) {
      // First, extract any text that's not part of a block
      let remainingContent = promptData.content;
      
      // Remove all block content from the remaining content
      promptData.blocks.forEach(block => {
        if (block.content) {
          remainingContent = remainingContent.replace(block.content, '');
        }
      });
      
      // Clean up the remaining content (remove extra newlines, etc.)
      remainingContent = remainingContent.replace(/\n{3,}/g, '\n\n').trim();
      
      // Now rebuild the content with the new block order
      newContent = newBlocks.map(block => block.content).join('\n\n');
      
      // Add the remaining content if there is any
      if (remainingContent) {
        newContent = remainingContent + '\n\n' + newContent;
      }
    } else {
      // If there's no custom content, just use the blocks
      newContent = newBlocks.map(block => block.content).join('\n\n');
    }

    setPromptData({
      ...promptData,
      blocks: newBlocks,
      content: newContent
    });
  };

  // Handle adding a block
  const handleAddBlock = (blockContent) => {
    // Create a new block
    const newBlock = {
      id: `custom-${Date.now()}`,
      title: 'Custom Block',
      content: blockContent,
      category: 'custom'
    };

    // Add the block to the prompt
    const newBlocks = [...promptData.blocks, newBlock];
    
    // Update the content to include the block
    let newContent = promptData.content;
    if (newContent) {
      newContent = newContent.trim() + '\n\n' + blockContent;
    } else {
      newContent = blockContent;
    }

    setPromptData({
      ...promptData,
      blocks: newBlocks,
      content: newContent
    });
  };

  // Validate the form
  const validateForm = () => {
    const newErrors = {};

    if (!promptData.title.trim()) {
      newErrors.title = 'Title is required';
    }

    if (!promptData.content.trim()) {
      newErrors.content = 'Prompt content is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleToggleFavorite = () => {
    setPromptData({
      ...promptData,
      is_favorite: !promptData.is_favorite
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onSave(promptData);
    }
  };

  const handleTest = async () => {
    if (validateForm()) {
      // Start timing the execution
      const startTime = Date.now();
      
      try {
        // Call the original onTest function and get the result
        const result = await onTest(promptData);
        
        // Calculate execution time in milliseconds
        const executionTime = Date.now() - startTime;
        
        // Save the execution history if we have a user and a valid promptId
        console.log('Saving execution history:', { user, promptId });
        if (user && !isNewPrompt && promptId) {
          try {
            // Log the data we're about to save
            const executionData = {
              userId: user.id,
              promptId: promptId,
              promptData: {
                title: promptData.title,
                content: promptData.content,
                model: promptData.model,
                provider: promptData.provider
              },
              variables: promptData.variables,
              result: result,
              status: 'success',
              executionTime: executionTime,
              model: promptData.model,
              provider: promptData.provider
            };

            console.log('Execution data to save:', executionData);

            const savedExecution = await promptExecutionsService.saveExecution(executionData);
            console.log('Prompt execution saved to history with ID:', savedExecution?.id);

            // Update history key to force refresh and switch to the history tab
            setHistoryKey(Date.now());
            setResultsTab(1);
          } catch (error) {
            console.error('Failed to save prompt execution history:', error);
          }
        }
        
        return result;
      } catch (error) {
        // If there was an error, still save the execution but mark it as failed
        if (user && !isNewPrompt && promptId) {
          try {
            // Log the data we're about to save
            const executionData = {
              userId: user.id,
              promptId: promptId,
              promptData: {
                title: promptData.title,
                content: promptData.content,
                model: promptData.model,
                provider: promptData.provider
              },
              variables: promptData.variables,
              result: { error: error.message || 'Unknown error' },
              status: 'error',
              executionTime: Date.now() - startTime,
              model: promptData.model,
              provider: promptData.provider
            };

            console.log('Error execution data to save:', executionData);

            const savedExecution = await promptExecutionsService.saveExecution(executionData);
            console.log('Failed prompt execution saved to history with ID:', savedExecution?.id);

            // Update history key to force refresh and switch to the history tab
            setHistoryKey(Date.now());
            setResultsTab(1);
          } catch (saveError) {
            console.error('Failed to save prompt execution history:', saveError);
          }
        }
        
        // Re-throw the original error
        throw error;
      }
    }
  };

  // Tab change handlers
  const handleDetailsTabChange = (event, newValue) => {
    setDetailsTab(newValue);
  };

  const handleBuilderTabChange = (event, newValue) => {
    setBuilderTab(newValue);
  };

  const handleResultsTabChange = (event, newValue) => {
    setResultsTab(newValue);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* Section 1: Prompt Details */}
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title="Prompt Details"
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={promptData.is_favorite ? "Remove from favorites" : "Add to favorites"}>
                <IconButton
                  onClick={handleToggleFavorite}
                  color="primary"
                  sx={{ mr: 1 }}
                >
                  {promptData.is_favorite ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
              </Tooltip>
              <Button
                variant="contained"
                startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                type="submit"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save Prompt"}
              </Button>
            </Box>
          }
        />
        <Divider />
        <CardContent sx={{ pb: 0 }}>
          <Tabs value={detailsTab} onChange={handleDetailsTabChange}>
            <Tab label="Settings" />
            <Tab label="Variables" />
          </Tabs>
          
          {/* Settings Tab */}
          <Box sx={{ mt: 2, display: detailsTab === 0 ? 'block' : 'none' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={promptData.title}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.title}
                  helperText={errors.title}
                  required
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  value={promptData.description}
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={2}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>AI Provider</InputLabel>
                  <Select
                    name="provider"
                    value={promptData.provider}
                    label="AI Provider"
                    onChange={handleChange}
                  >
                    {providers.map(provider => (
                      <MenuItem key={provider.id} value={provider.id}>{provider.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Model</InputLabel>
                  <Select
                    name="model"
                    value={promptData.model}
                    label="Model"
                    onChange={handleChange}
                  >
                    {providers
                      .find(p => p.id === promptData.provider)?.models
                      ?.map(model => (
                        <MenuItem key={model} value={model}>{model}</MenuItem>
                      )) || []
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Tags
                </Typography>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Autocomplete
                    freeSolo
                    size="small"
                    options={availableTags.filter(tag => !promptData.tags.includes(tag))}
                    value={newTag}
                    onChange={(event, newValue) => setNewTag(newValue || '')}
                    inputValue={newTag}
                    onInputChange={(event, newInputValue) => setNewTag(newInputValue)}
                    sx={{ flexGrow: 1, mr: 1 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add tag"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                <LocalOfferIcon color="action" fontSize="small" />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LocalOfferIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                          {option}
                        </Box>
                      </li>
                    )}
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleAddTag}
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button>
                </Box>
                <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
                  {promptData.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDeleteTag(tag)}
                      color="primary"
                      variant="outlined"
                      size="small"
                      icon={<LocalOfferIcon />}
                    />
                  ))}
                  {promptData.tags.length === 0 && (
                    <Typography variant="body2" color="text.secondary">
                      No tags added yet
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          
          {/* Variables Tab */}
          <Box sx={{ mt: 2, display: detailsTab === 1 ? 'block' : 'none' }}>
            <Box sx={{ mb: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Variable Name"
                    name="name"
                    value={newVariable.name}
                    onChange={handleVariableChange}
                    placeholder="e.g. topic"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Description"
                    name="description"
                    value={newVariable.description}
                    onChange={handleVariableChange}
                    placeholder="What this variable represents"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Example"
                    name="example"
                    value={newVariable.example}
                    onChange={handleVariableChange}
                    placeholder="e.g. data science"
                  />
                </Grid>
              </Grid>
              <Button
                variant="outlined"
                size="small"
                onClick={handleAddVariable}
                sx={{ mt: 2 }}
                startIcon={<AddIcon />}
              >
                Add Variable
              </Button>
            </Box>

            <Grid container spacing={2}>
              {promptData.variables.map((variable, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Card variant="outlined">
                    <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle2" color="primary">
                          {`{{${variable.name}}}`}
                        </Typography>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDeleteVariable(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {variable.description}
                      </Typography>
                      {variable.example && (
                        <Typography variant="caption" display="block" sx={{ mt: 0.5 }}>
                          Example: {variable.example}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {promptData.variables.length === 0 && (
              <Typography variant="body2" color="text.secondary" align="center">
                No variables added yet
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>

      {/* Section 2: Prompt Builder */}
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title="Prompt Builder"
        />
        <Divider />
        <CardContent sx={{ pb: 0 }}>
          <Tabs value={builderTab} onChange={handleBuilderTabChange}>
            <Tab label="Compose" />
            <Tab label="Preview" />
          </Tabs>
          
          {/* Compose Tab */}
          <Box sx={{ mt: 2, display: builderTab === 0 ? 'block' : 'none' }}>
            <PromptModuleBuilder
              content={promptData.content}
              blocks={promptData.blocks || []}
              onContentChange={handleContentChange}
              onBlocksChange={handleBlocksChange}
              onRemoveBlock={handleRemoveBlock}
              onMoveBlock={handleMoveBlock}
              onAddBlock={handleAddBlock}
              onOpenBlockLibrary={handleOpenBlocksDialog}
            />
          </Box>
          
          {/* Preview Tab */}
          <Box sx={{ mt: 2, display: builderTab === 1 ? 'block' : 'none' }}>
            <Typography variant="body2" color="text.secondary" paragraph>
              This is how your prompt will look with example values:
            </Typography>

            <Box sx={{ p: 3, bgcolor: 'background.default', borderRadius: 1, mb: 2, maxHeight: '400px', overflow: 'auto' }}>
              {promptData.content ? (
                <Typography variant="body1" sx={{
                  whiteSpace: 'pre-line',
                  '& code': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    padding: '2px 4px',
                    borderRadius: '4px',
                    fontFamily: 'monospace',
                  },
                  '& pre': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    padding: '8px',
                    borderRadius: '4px',
                    overflowX: 'auto',
                    fontFamily: 'monospace',
                  },
                  '& h1, & h2, & h3, & h4, & h5, & h6': {
                    margin: '16px 0 8px 0',
                    fontWeight: 'bold',
                  },
                  '& h1': { fontSize: '1.5rem' },
                  '& h2': { fontSize: '1.3rem' },
                  '& h3': { fontSize: '1.2rem' },
                  '& h4': { fontSize: '1.1rem' },
                  '& ul, & ol': {
                    paddingLeft: '24px',
                    margin: '8px 0',
                  },
                  '& blockquote': {
                    borderLeft: '3px solid',
                    borderColor: 'primary.main',
                    paddingLeft: '16px',
                    margin: '16px 0',
                    fontStyle: 'italic',
                  },
                  '& a': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                  '& table': {
                    borderCollapse: 'collapse',
                    width: '100%',
                    margin: '16px 0',
                  },
                  '& th, & td': {
                    border: '1px solid',
                    borderColor: 'divider',
                    padding: '8px',
                    textAlign: 'left',
                  },
                  '& th': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    fontWeight: 'bold',
                  }
                }}>
                  {promptData.variables.reduce((content, variable) => {
                    return content.replace(
                      new RegExp(`{{${variable.name}}}`, 'g'),
                      variable.example ? `<strong>${variable.example}</strong>` : `<em>{{${variable.name}}}</em>`
                    );
                  }, promptData.content)
                    .split(/(<strong>.*?<\/strong>|<em>.*?<\/em>)/)
                    .map((part, i) => {
                      if (part.startsWith('<strong>')) {
                        return <Box component="span" key={i} sx={{ fontWeight: 'bold', color: 'primary.main', backgroundColor: 'rgba(25, 118, 210, 0.08)', padding: '2px 4px', borderRadius: '4px' }}>
                          {part.replace(/<strong>(.*?)<\/strong>/, '$1')}
                        </Box>;
                      } else if (part.startsWith('<em>')) {
                        return <Box component="span" key={i} sx={{ fontStyle: 'italic', color: 'text.secondary', backgroundColor: 'rgba(0, 0, 0, 0.04)', padding: '2px 4px', borderRadius: '4px' }}>
                          {part.replace(/<em>(.*?)<\/em>/, '$1')}
                        </Box>;
                      }
                      return part;
                    })}
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary" align="center">
                  Enter a prompt template to see the preview
                </Typography>
              )}
            </Box>
          </Box>

          {/* Action Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleTest}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <PlayArrowIcon />}
              sx={{ mr: 1 }}
            >
              {loading ? "Running..." : "Complete Prompt"}
            </Button>
            <Button
              variant="contained"
              startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
              type="submit"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Prompt"}
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Section 3: Results */}
      <Card>
        <CardHeader title="Results" />
        <Divider />
        <CardContent sx={{ pb: 0 }}>
          <Tabs value={resultsTab} onChange={handleResultsTabChange}>
            <Tab label="Response" />
            {!isNewPrompt && <Tab label="Execution History" />}
          </Tabs>
          
          {/* Response Tab */}
          <Box sx={{ mt: 2, display: resultsTab === 0 ? 'block' : 'none' }}>
            {aiResponse ? (
              <Box ref={responseRef}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                  <Tooltip title="Copy response">
                    <IconButton size="small" onClick={onCopyResponse}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <Box
                    className="markdown-response"
                    sx={{
                      maxHeight: '500px',
                      overflow: 'auto',
                      fontSize: '0.9rem',
                      lineHeight: 1.6,
                      '& p': {
                        marginBottom: '16px',
                      },
                      '& h1, & h2, & h3, & h4, & h5, & h6': {
                        margin: '24px 0 16px 0',
                        fontWeight: 'bold',
                        lineHeight: 1.3,
                      },
                      '& h1': { fontSize: '1.7rem' },
                      '& h2': { fontSize: '1.5rem' },
                      '& h3': { fontSize: '1.3rem' },
                      '& h4': { fontSize: '1.1rem' },
                      '& h5': { fontSize: '1rem' },
                      '& h6': { fontSize: '0.9rem' },
                      '& ul, & ol': {
                        paddingLeft: '24px',
                        margin: '16px 0',
                      },
                      '& li': {
                        margin: '8px 0',
                      },
                      '& blockquote': {
                        borderLeft: '4px solid',
                        borderColor: 'primary.main',
                        paddingLeft: '16px',
                        margin: '16px 0',
                        fontStyle: 'italic',
                        color: 'text.secondary',
                      },
                      '& a': {
                        color: 'primary.main',
                        textDecoration: 'underline',
                      },
                      '& table': {
                        borderCollapse: 'collapse',
                        width: '100%',
                        margin: '16px 0',
                      },
                      '& th, & td': {
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: '8px',
                        textAlign: 'left',
                      },
                      '& th': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        fontWeight: 'bold',
                      },
                      '& code': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        padding: '2px 4px',
                        borderRadius: '4px',
                        fontFamily: 'monospace',
                        fontSize: '0.85em',
                      },
                      '& .language-indicator': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        padding: '4px 8px',
                        fontSize: '0.75rem',
                        fontFamily: 'monospace',
                        color: 'text.secondary',
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                      },
                      '& .code-block': {
                        position: 'relative',
                        margin: '16px 0',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                      },
                      '& .code-content': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        padding: '16px 8px 8px 8px',
                        overflowX: 'auto',
                        fontFamily: 'monospace',
                        fontSize: '0.875rem',
                        whiteSpace: 'pre',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: aiResponse ? formatCodeWithLanguage(aiResponse) : ''
                    }}
                  />
                </Paper>
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 4 }}>
                Run the prompt to see the AI response here.
              </Typography>
            )}
          </Box>
          
          {/* History Tab */}
          {!isNewPrompt && (
            <Box sx={{ mt: 2, display: resultsTab === 1 ? 'block' : 'none' }}>
              <ExecutionHistory promptId={promptId} key={`history-${promptId}-${historyKey}`} />
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 2, textAlign: 'center' }}>
                Note: Execution history is only available for saved prompts
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Block Library Dialog */}
      <Dialog
        open={openBlocksDialog}
        onClose={handleCloseBlocksDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Prompt Block Library</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={0}>
              <Tab label="API Service" />
            </Tabs>
          </Box>
          <ApiBlockLibrary
            onSelectBlock={handleSelectBlock}
            selectedBlocks={promptData.blocks || []}
            onBlockUpdate={(updatedBlock) => {
              // Update the block in the prompt if it's already included
              if (promptData.blocks.some(block => block.id === updatedBlock.id)) {
                // Create a new array with the updated block
                const updatedBlocks = promptData.blocks.map(block =>
                  block.id === updatedBlock.id ? updatedBlock : block
                );

                // Get the old block
                const oldBlock = promptData.blocks.find(block => block.id === updatedBlock.id);

                // Rebuild the content from scratch to ensure proper ordering
                // This is more reliable than simple string replacement
                const updatedBlocksOrdered = promptData.blocks.map(block =>
                  block.id === updatedBlock.id ? updatedBlock : block
                );

                // Rebuild content from blocks
                let newContent = '';

                // If we have text content outside of blocks, we need to preserve it
                if (promptData.content) {
                  // First, extract any text that's not part of a block
                  let remainingContent = promptData.content;

                  // Remove all block content from the remaining content
                  promptData.blocks.forEach(block => {
                    if (block.content) {
                      // Use split and join to ensure all instances are replaced
                      remainingContent = remainingContent.split(block.content).join('§BLOCK_PLACEHOLDER§');
                    }
                  });

                  // Clean up the remaining content (remove extra newlines, etc.)
                  remainingContent = remainingContent.replace(/§BLOCK_PLACEHOLDER§/g, '')
                                                    .replace(/\n{3,}/g, '\n\n')
                                                    .trim();

                  // Now rebuild the content with updated blocks
                  newContent = updatedBlocksOrdered.map(block => block.content).join('\n\n');

                  // Add the remaining content if there is any
                  if (remainingContent) {
                    newContent = remainingContent + '\n\n' + newContent;
                  }
                } else {
                  // If there's no custom content, just use the blocks
                  newContent = updatedBlocksOrdered.map(block => block.content).join('\n\n');
                }

                // Update the prompt data with the new blocks and content
                setPromptData({
                  ...promptData,
                  blocks: updatedBlocks,
                  content: newContent
                });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBlocksDialog}>Close</Button>
        </DialogActions>
      </Dialog>


    </Box>
  );
};

export default PromptFormNew;