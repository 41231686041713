import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Alert
} from '@mui/material';
import ApiPromptLibrary from '../components/prompts/ApiPromptLibrary.jsx';
import { useAuth } from '../contexts/AuthContext';

function PromptLibrary() {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [showFavorites, setShowFavorites] = useState(false);

  // Check for filter parameter in URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get('filter');
    setShowFavorites(filter === 'favorites');
  }, [location.search]);

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {showFavorites ? 'Favorite Prompts' : 'Prompt Library'}
      </Typography>

      {!user ? (
        <Alert severity="info">
          Please log in to manage your prompts.
        </Alert>
      ) : (
        <ApiPromptLibrary showFavorites={showFavorites} />
      )}
    </Box>
  );
}

export default PromptLibrary;