import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Collapse,
  Chip,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { promptExecutionsService } from '../../services/apiService';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../services/supabase';

const ExecutionHistory = ({ promptId, key }) => {
  const { user } = useAuth();
  const [executions, setExecutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [detailsDialog, setDetailsDialog] = useState({ open: false, execution: null });

  useEffect(() => {
    const loadExecutions = async () => {
      if (!user || !promptId) {
        console.log('Missing user or promptId for execution history:', { user, promptId });
        setLoading(false);
        return;
      }

      try {
        console.log('Loading execution history for prompt:', promptId);
        console.log('User ID:', user.id);
        setLoading(true);

        // Check if the prompt exists
        try {
          const { data: promptData, error: promptError } = await supabase
            .from('prompts')
            .select('id')
            .eq('id', promptId)
            .single();

          if (promptError) {
            console.error('Error checking prompt existence:', promptError);
          } else {
            console.log('Prompt exists:', promptData);
          }
        } catch (err) {
          console.error('Error checking prompt:', err);
        }

        // Check if there are any executions in the database
        try {
          const { data: allExecs, error: allExecsError } = await supabase
            .from('prompt_executions')
            .select('count')
            .limit(1);

          if (allExecsError) {
            console.error('Error checking executions:', allExecsError);
          } else {
            console.log('Executions in database:', allExecs);
          }
        } catch (err) {
          console.error('Error checking executions:', err);
        }

        const data = await promptExecutionsService.getExecutionsByPromptId(user.id, promptId);
        console.log('Loaded execution history:', data);
        setExecutions(data);
      } catch (error) {
        console.error('Error loading execution history:', error);
      } finally {
        setLoading(false);
      }
    };

    console.log('ExecutionHistory component mounted with promptId:', promptId);
    loadExecutions();
  }, [user, promptId]);

  const handleToggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleCopyResult = (result) => {
    if (typeof result === 'string') {
      navigator.clipboard.writeText(result);
    } else if (result && result.text) {
      navigator.clipboard.writeText(result.text);
    } else {
      navigator.clipboard.writeText(JSON.stringify(result, null, 2));
    }
  };

  const handleDeleteExecution = async (executionId) => {
    try {
      await promptExecutionsService.deleteExecution(executionId, user.id);
      setExecutions(executions.filter(exec => exec.id !== executionId));
    } catch (error) {
      console.error('Error deleting execution:', error);
    }
  };

  const handleOpenDetails = (execution) => {
    setDetailsDialog({ open: true, execution });
  };

  const handleCloseDetails = () => {
    setDetailsDialog({ open: false, execution: null });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const formatExecutionTime = (ms) => {
    if (ms < 1000) return `${ms}ms`;
    return `${(ms / 1000).toFixed(2)}s`;
  };

  const getResultText = (result) => {
    if (typeof result === 'string') return result;
    if (result && result.text) return result.text;
    return JSON.stringify(result, null, 2);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (executions.length === 0) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="body1" color="text.secondary">
          No execution history available for this prompt.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Run the prompt to see results here.
        </Typography>
      </Paper>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Execution History
      </Typography>
      <Paper>
        <List>
          {executions.map((execution, index) => (
            <React.Fragment key={execution.id || index}>
              <ListItem
                button
                onClick={() => handleToggleExpand(execution.id)}
                sx={{ 
                  bgcolor: execution.status === 'error' ? 'error.50' : 'background.paper',
                  '&:hover': { bgcolor: execution.status === 'error' ? 'error.100' : 'action.hover' }
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1">
                        {formatDate(execution.created_at)}
                      </Typography>
                      <Chip
                        size="small"
                        label={execution.status === 'success' ? 'Success' : 'Error'}
                        color={execution.status === 'success' ? 'success' : 'error'}
                        sx={{ ml: 1 }}
                      />
                      {execution.execution_time && (
                        <Chip
                          size="small"
                          label={formatExecutionTime(execution.execution_time)}
                          variant="outlined"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  }
                  secondary={
                    <Typography variant="body2" noWrap>
                      {execution.model} ({execution.provider})
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDetails(execution);
                    }}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyResult(execution.result);
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteExecution(execution.id);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleToggleExpand(execution.id)}>
                    {expandedId === execution.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={expandedId === execution.id} timeout="auto" unmountOnExit>
                <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Result:
                  </Typography>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 2,
                      maxHeight: '200px',
                      overflow: 'auto',
                      whiteSpace: 'pre-wrap',
                      fontFamily: 'monospace',
                      fontSize: '0.875rem'
                    }}
                  >
                    {getResultText(execution.result)}
                  </Paper>
                </Box>
              </Collapse>
              {index < executions.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>

      {/* Details Dialog */}
      <Dialog
        open={detailsDialog.open}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Execution Details</DialogTitle>
        <DialogContent>
          {detailsDialog.execution && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Execution Time: {formatDate(detailsDialog.execution.created_at)}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Duration: {formatExecutionTime(detailsDialog.execution.execution_time || 0)}
              </Typography>
              <Divider sx={{ my: 2 }} />
              
              <Typography variant="subtitle2" gutterBottom>
                Model: {detailsDialog.execution.model} ({detailsDialog.execution.provider})
              </Typography>
              
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Prompt:
              </Typography>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  maxHeight: '200px',
                  overflow: 'auto',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  fontSize: '0.875rem'
                }}
              >
                {detailsDialog.execution.prompt_data?.content || 'No prompt content available'}
              </Paper>
              
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Variables:
              </Typography>
              {detailsDialog.execution.variables && detailsDialog.execution.variables.length > 0 ? (
                <List dense>
                  {detailsDialog.execution.variables.map((variable, idx) => (
                    <ListItem key={idx}>
                      <ListItemText
                        primary={`${variable.name}: ${variable.example || 'No example'}`}
                        secondary={variable.description}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No variables used
                </Typography>
              )}
              
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Result:
              </Typography>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  maxHeight: '300px',
                  overflow: 'auto',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'monospace',
                  fontSize: '0.875rem'
                }}
              >
                {getResultText(detailsDialog.execution.result)}
              </Paper>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
          <Button
            onClick={() => {
              if (detailsDialog.execution) {
                handleCopyResult(detailsDialog.execution.result);
              }
            }}
            startIcon={<ContentCopyIcon />}
          >
            Copy Result
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExecutionHistory;