import { supabase } from './supabase';

// Table name
const TABLE_NAME = 'api_keys';

/**
 * Get all API keys for a user
 * @param {string} userId - The user ID
 * @returns {Promise<Array>} - Array of API keys
 */
export const getApiKeys = async (userId) => {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select('*')
    .eq('user_id', userId);
  
  if (error) {
    console.error('Error fetching API keys:', error);
    throw error;
  }
  
  return data;
};

/**
 * Get an API key by provider ID
 * @param {string} userId - The user ID
 * @param {string} providerId - The provider ID
 * @returns {Promise<Object>} - The API key object
 */
export const getApiKeyByProvider = async (userId, providerId) => {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select('*')
    .eq('user_id', userId)
    .eq('provider_id', providerId)
    .single();
  
  if (error && error.code !== 'PGRST116') { // PGRST116 is the error code for no rows returned
    console.error('Error fetching API key:', error);
    throw error;
  }
  
  return data;
};

/**
 * Create or update an API key
 * @param {Object} apiKeyData - The API key data
 * @returns {Promise<Object>} - The created or updated API key
 */
export const upsertApiKey = async (apiKeyData) => {
  // Check if the API key already exists
  const { data: existingKey } = await supabase
    .from(TABLE_NAME)
    .select('*')
    .eq('user_id', apiKeyData.user_id)
    .eq('provider_id', apiKeyData.provider_id)
    .maybeSingle();
  
  if (existingKey) {
    // Update existing key
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .update(apiKeyData)
      .eq('id', existingKey.id)
      .select();
    
    if (error) {
      console.error('Error updating API key:', error);
      throw error;
    }
    
    return data[0];
  } else {
    // Create new key
    const { data, error } = await supabase
      .from(TABLE_NAME)
      .insert([apiKeyData])
      .select();
    
    if (error) {
      console.error('Error creating API key:', error);
      throw error;
    }
    
    return data[0];
  }
};

/**
 * Delete an API key
 * @param {string} userId - The user ID
 * @param {string} providerId - The provider ID
 * @returns {Promise<void>}
 */
export const deleteApiKey = async (userId, providerId) => {
  const { error } = await supabase
    .from(TABLE_NAME)
    .delete()
    .eq('user_id', userId)
    .eq('provider_id', providerId);
  
  if (error) {
    console.error('Error deleting API key:', error);
    throw error;
  }
};

/**
 * Get all active API keys for a user
 * @param {string} userId - The user ID
 * @returns {Promise<Array>} - Array of active API keys
 */
export const getActiveApiKeys = async (userId) => {
  const { data, error } = await supabase
    .from(TABLE_NAME)
    .select('*')
    .eq('user_id', userId)
    .eq('is_active', true);
  
  if (error) {
    console.error('Error fetching active API keys:', error);
    throw error;
  }
  
  return data;
};