import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSidebar } from '../contexts/SidebarContext';
import {
  Box,
  Typography,
  Paper,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Divider,
  CircularProgress,
  Tabs,
  Tab
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';
import PromptFormNew from '../components/prompts/PromptFormNew';
import ExecutionHistory from '../components/prompts/ExecutionHistory';
import { promptsService } from '../services/apiService';
import { useAuth } from '../contexts/AuthContext';

function PromptEditor() {
  const { promptId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { sidebarOpen, toggleSidebar } = useSidebar();
  const [isNewPrompt, setIsNewPrompt] = useState(!promptId || promptId === 'new');

  const [aiResponse, setAiResponse] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [promptData, setPromptData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  // Mock data for existing prompts
  const mockPrompts = {
    '1': {
      id: '1',
      title: 'Creative Story Generator',
      description: 'Generates creative stories based on provided themes and characters.',
      content: 'Create a {{genre}} story about {{character}} who {{situation}}. The story should be {{length}} and include themes of {{theme}}.',
      model: 'gpt-4',
      tags: ['creative', 'writing', 'stories'],
      variables: [
        { name: 'genre', description: 'The genre of the story', example: 'science fiction' },
        { name: 'character', description: 'The main character', example: 'a time-traveling archaeologist' },
        { name: 'situation', description: 'The situation or conflict', example: 'discovers an ancient artifact that can predict the future' },
        { name: 'length', description: 'The approximate length', example: 'approximately 500 words' },
        { name: 'theme', description: 'Main themes to include', example: 'destiny and free will' }
      ]
    },
    '2': {
      id: '2',
      title: 'Technical Documentation Helper',
      description: 'Helps create technical documentation with clear explanations.',
      content: 'Create technical documentation for {{technology}} that explains {{feature}}. The documentation should be aimed at {{audience}} and include {{elements}}. Use {{format}} formatting.',
      model: 'gpt-3.5-turbo',
      tags: ['technical', 'documentation'],
      variables: [
        { name: 'technology', description: 'The technology being documented', example: 'React.js' },
        { name: 'feature', description: 'The specific feature to explain', example: 'hooks and state management' },
        { name: 'audience', description: 'The target audience', example: 'intermediate developers' },
        { name: 'elements', description: 'Elements to include', example: 'code examples, diagrams, and troubleshooting tips' },
        { name: 'format', description: 'The formatting style', example: 'Markdown' }
      ]
    }
  };

  // Update isNewPrompt when promptId changes
  useEffect(() => {
    setIsNewPrompt(!promptId || promptId === 'new');
  }, [promptId]);

  // Load prompt data if editing an existing prompt
  useEffect(() => {
    if (!isNewPrompt) {
      setLoading(true);

      // Load prompt from API
      const loadPrompt = async () => {
        try {
          const prompt = await promptsService.getPromptById(promptId);
          setPromptData(prompt);
        } catch (err) {
          console.error('Error loading prompt:', err);
          setSnackbar({
            open: true,
            message: 'Error loading prompt: ' + (err.message || 'Unknown error'),
            severity: 'error'
          });
          navigate('/library');
        } finally {
          setLoading(false);
        }
      };

      loadPrompt();
    } else {
      setPromptData({
        title: '',
        description: '',
        content: '',
        provider: 'openai',
        model: 'gpt-4',
        tags: [],
        variables: [],
        blocks: []
      });
    }
  }, [promptId, isNewPrompt, navigate]);

  const handleSavePrompt = async (data) => {
    if (!user) {
      setSnackbar({
        open: true,
        message: 'You must be logged in to save prompts',
        severity: 'error'
      });
      return;
    }

    setLoading(true);

    try {
      // Add user_id to the data
      const promptWithUserId = {
        ...data,
        user_id: user.id
      };

      let result;

      if (isNewPrompt) {
        // Create new prompt
        result = await promptsService.createPrompt(promptWithUserId);
      } else {
        // Update existing prompt
        result = await promptsService.updatePrompt(promptId, promptWithUserId);
      }

      console.log('Saved prompt:', result);

      setSnackbar({
        open: true,
        message: 'Prompt saved successfully!',
        severity: 'success'
      });

      // If it's a new prompt, navigate to the edit page for the newly created prompt
      if (isNewPrompt && result && result.id) {
        // Update the promptData with the saved result
        setPromptData(result);

        // Navigate to the edit page for the newly created prompt without triggering a page reload
        navigate(`/editor/${result.id}`, { replace: true });

        // Update isNewPrompt state to reflect we're now editing an existing prompt
        setIsNewPrompt(false);
      }
    } catch (err) {
      console.error('Error saving prompt:', err);
      setSnackbar({
        open: true,
        message: 'Error saving prompt: ' + (err.message || 'Unknown error'),
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Create a ref for the response section
  const responseRef = React.useRef(null);

  const handleTestPrompt = async (data) => {
    setLoading(true);
    setAiResponse('');
    let responseData = null;

    try {
      // Check if we should use the OpenAI API
      if (data.provider === 'openai') {
        // Import the OpenAI service dynamically
        const { generateCompletion } = await import('../services/openaiService');

        // Process variables in the prompt if any
        let processedContent = data.content;
        if (data.variables && data.variables.length > 0) {
          // Replace variables with their example values if available
          data.variables.forEach(variable => {
            if (variable.example) {
              const regex = new RegExp(`{{${variable.name}}}`, 'g');
              processedContent = processedContent.replace(regex, variable.example);
            }
          });
        }

        // Call the OpenAI API
        const response = await generateCompletion(processedContent, data.model);

        if (response && response.choices && response.choices.length > 0) {
          const responseText = response.choices[0].message.content;
          setAiResponse(responseText);
          responseData = {
            text: responseText,
            raw: response
          };
        } else {
          throw new Error('No response content received from API');
        }
      } else {
        // Fallback to simulated response for other providers
        const responseText = `This is a simulated AI response for ${data.provider} based on your prompt template:

"${data.content}"

In a real application, this would be the result from an API call to the ${data.model} model with your prompt and any provided variable values.

The response would be tailored based on the specific instructions and variables you've defined in your prompt template.`;

        // Simulate API delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        setAiResponse(responseText);
        responseData = {
          text: responseText,
          simulated: true
        };
      }

      // Return the response data for the execution history
      return responseData;
    } catch (error) {
      console.error('Error running prompt:', error);
      setSnackbar({
        open: true,
        message: `Error running prompt: ${error.message || 'Unknown error'}`,
        severity: 'error'
      });

      // Set a fallback response
      const errorMessage = `Error: Failed to get a response from the API. ${error.message || 'Unknown error'}`;
      setAiResponse(errorMessage);

      // Throw the error to be caught by the caller
      throw error;
    } finally {
      setLoading(false);

      // Scroll to the response section after a short delay to ensure it's rendered
      setTimeout(() => {
        if (responseRef.current) {
          responseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCopyResponse = () => {
    navigator.clipboard.writeText(aiResponse);
    setSnackbar({
      open: true,
      message: 'Response copied to clipboard',
      severity: 'success'
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading && !promptData) {
    return (
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Typography>Loading prompt data...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{ mr: 1 }}
            aria-label="go back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">
            {isNewPrompt ? 'Create New Prompt' : 'Edit Prompt'}
          </Typography>
        </Box>
        <Tooltip title={sidebarOpen ? "Expand workspace" : "Restore sidebar"}>
          <IconButton onClick={toggleSidebar} color="primary">
            {sidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {promptData && (
        <PromptFormNew
          initialData={promptData}
          onSave={handleSavePrompt}
          onTest={handleTestPrompt}
          loading={loading}
          aiResponse={aiResponse}
          onCopyResponse={handleCopyResponse}
          responseRef={responseRef}
          promptId={promptId}
          isNewPrompt={isNewPrompt}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default PromptEditor;