import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Collapse,
  Chip,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { promptExecutionsService } from '../services/apiService';
import { useAuth } from '../contexts/AuthContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const History = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const executionId = query.get('execution');
  
  const [executions, setExecutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null);
  const [detailsDialog, setDetailsDialog] = useState({ open: false, execution: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const loadExecutions = async () => {
      if (!user) return;

      try {
        setLoading(true);
        const data = await promptExecutionsService.getAllExecutions(user.id);
        setExecutions(data);
        
        // If there's an executionId in the URL, open that execution's details
        if (executionId) {
          const execution = data.find(e => e.id === executionId);
          if (execution) {
            setDetailsDialog({ open: true, execution });
          }
        }
      } catch (error) {
        console.error('Error loading executions:', error);
      } finally {
        setLoading(false);
      }
    };

    loadExecutions();
  }, [user, executionId]);

  const handleToggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleCopyResult = (result) => {
    if (typeof result === 'string') {
      navigator.clipboard.writeText(result);
    } else if (result && result.text) {
      navigator.clipboard.writeText(result.text);
    } else {
      navigator.clipboard.writeText(JSON.stringify(result, null, 2));
    }
  };

  const handleDeleteExecution = async (executionId) => {
    try {
      await promptExecutionsService.deleteExecution(executionId, user.id);
      setExecutions(executions.filter(exec => exec.id !== executionId));
      
      // If the deleted execution is currently shown in the details dialog, close it
      if (detailsDialog.execution && detailsDialog.execution.id === executionId) {
        setDetailsDialog({ open: false, execution: null });
      }
    } catch (error) {
      console.error('Error deleting execution:', error);
    }
  };

  const handleOpenDetails = (execution) => {
    setDetailsDialog({ open: true, execution });
    
    // Update the URL without reloading the page
    const newUrl = execution ?
      `${window.location.pathname}?execution=${execution.id}` :
      window.location.pathname;
    window.history.pushState({}, '', newUrl);
  };

  const handleCloseDetails = () => {
    setDetailsDialog({ open: false, execution: null });
    
    // Remove the execution parameter from the URL
    window.history.pushState({}, '', window.location.pathname);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const formatExecutionTime = (ms) => {
    if (!ms) return 'N/A';
    if (ms < 1000) return `${ms}ms`;
    return `${(ms / 1000).toFixed(2)}s`;
  };

  const getResultText = (result) => {
    if (typeof result === 'string') return result;
    if (result && result.text) return result.text;
    return JSON.stringify(result, null, 2);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event, newValue) => {
    setActiveTab(newValue);
    setFilterStatus(newValue === 0 ? 'all' : (newValue === 1 ? 'success' : 'error'));
  };

  // Filter executions based on search term and status filter
  const filteredExecutions = executions.filter(execution => {
    const matchesSearch = 
      execution.prompt_data?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      execution.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      execution.provider?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesFilter = 
      filterStatus === 'all' || 
      execution.status === filterStatus;
    
    return matchesSearch && matchesFilter;
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Execution History</Typography>
      
      <Paper sx={{ mb: 3, p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search by prompt title, model, or provider"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Tabs value={activeTab} onChange={handleFilterChange} aria-label="filter tabs">
              <Tab label="All" />
              <Tab label="Success" />
              <Tab label="Error" />
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      
      {filteredExecutions.length > 0 ? (
        <Paper>
          <List>
            {filteredExecutions.map((execution, index) => (
              <React.Fragment key={execution.id || index}>
                <ListItem
                  button
                  onClick={() => handleToggleExpand(execution.id)}
                  sx={{ 
                    bgcolor: execution.status === 'error' ? 'error.50' : 'background.paper',
                    '&:hover': { bgcolor: execution.status === 'error' ? 'error.100' : 'action.hover' }
                  }}
                >
                  <ListItemIcon>
                    <PlayArrowIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1">
                          {execution.prompt_data?.title || 'Untitled Prompt'}
                        </Typography>
                        <Chip
                          size="small"
                          label={execution.status === 'success' ? 'Success' : 'Error'}
                          color={execution.status === 'success' ? 'success' : 'error'}
                          sx={{ ml: 1 }}
                        />
                      </Box>
                    }
                    secondary={
                      <Typography variant="body2">
                        {formatDate(execution.created_at)} • {execution.model} ({execution.provider})
                        {execution.execution_time && ` • ${formatExecutionTime(execution.execution_time)}`}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDetails(execution);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCopyResult(execution.result);
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteExecution(execution.id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton edge="end" onClick={() => handleToggleExpand(execution.id)}>
                      {expandedId === execution.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={expandedId === execution.id} timeout="auto" unmountOnExit>
                  <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Result:
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        maxHeight: '200px',
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                        fontFamily: 'monospace',
                        fontSize: '0.875rem'
                      }}
                    >
                      {getResultText(execution.result)}
                    </Paper>
                  </Box>
                </Collapse>
                {index < filteredExecutions.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No execution history found.
          </Typography>
          {searchTerm || filterStatus !== 'all' ? (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Try adjusting your search or filter criteria.
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Run prompts to see their execution history here.
            </Typography>
          )}
        </Paper>
      )}

      {/* Details Dialog */}
      <Dialog
        open={detailsDialog.open}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Execution Details</DialogTitle>
        <DialogContent>
          {detailsDialog.execution && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {detailsDialog.execution.prompt_data?.title || 'Untitled Prompt'}
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <Typography variant="body2" gutterBottom>
                        <strong>Execution Time:</strong> {formatDate(detailsDialog.execution.created_at)}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Duration:</strong> {formatExecutionTime(detailsDialog.execution.execution_time || 0)}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Model:</strong> {detailsDialog.execution.model}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Provider:</strong> {detailsDialog.execution.provider}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>Status:</strong> {detailsDialog.execution.status}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Prompt Content
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          maxHeight: '200px',
                          overflow: 'auto',
                          whiteSpace: 'pre-wrap',
                          fontFamily: 'monospace',
                          fontSize: '0.875rem'
                        }}
                      >
                        {detailsDialog.execution.prompt_data?.content || 'No content available'}
                      </Paper>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">
                          Result
                        </Typography>
                        <Button
                          startIcon={<ContentCopyIcon />}
                          onClick={() => handleCopyResult(detailsDialog.execution.result)}
                          size="small"
                        >
                          Copy
                        </Button>
                      </Box>
                      <Divider sx={{ mb: 2 }} />
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          maxHeight: '300px',
                          overflow: 'auto',
                          whiteSpace: 'pre-wrap',
                          fontFamily: 'monospace',
                          fontSize: '0.875rem'
                        }}
                      >
                        {getResultText(detailsDialog.execution.result)}
                      </Paper>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default History;