import { supabase } from './supabase';

// Blocks service
export const blocksService = {
  // Get all blocks for a user
  getBlocks: async (userId) => {
    try {
      console.log('Fetching blocks for user:', userId);

      const { data, error } = await supabase
        .from('blocks')
        .select('*')
        .eq('user_id', userId)
        .order('updated_at', { ascending: false });

      if (error) {
        console.error('Error fetching blocks:', error);
        return [];
      }

      console.log('Blocks response:', data);
      return data || [];
    } catch (error) {
      console.error('Error fetching blocks:', error);
      return [];
    }
  },

  // Get a block by ID
  getBlockById: async (blockId) => {
    try {
      const { data, error } = await supabase
        .from('blocks')
        .select('*')
        .eq('id', blockId)
        .single();

      if (error) {
        console.error('Error fetching block:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error fetching block:', error);
      throw error;
    }
  },

  // Create a new block
  createBlock: async (blockData) => {
    try {
      console.log('Creating block with data:', blockData);

      // Remove the id field when creating a new block to avoid duplicate key errors
      const { id, ...dataWithoutId } = blockData;

      const { data, error } = await supabase
        .from('blocks')
        .insert([dataWithoutId])
        .select()
        .single();

      if (error) {
        console.error('Error creating block:', error);
        throw error;
      }

      console.log('Create block response:', data);
      return data;
    } catch (error) {
      console.error('Error creating block:', error);
      throw error;
    }
  },

  // Update an existing block
  updateBlock: async (blockId, blockData) => {
    try {
      const { data, error } = await supabase
        .from('blocks')
        .update(blockData)
        .eq('id', blockId)
        .select()
        .single();

      if (error) {
        console.error('Error updating block:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error updating block:', error);
      throw error;
    }
  },

  // Delete a block
  deleteBlock: async (blockId, userId) => {
    try {
      // First, get the block to return it after deletion
      const { data: block, error: getError } = await supabase
        .from('blocks')
        .select('*')
        .eq('id', blockId)
        .eq('user_id', userId)
        .single();

      if (getError) {
        console.error('Error fetching block for deletion:', getError);
        throw getError;
      }

      // Delete the block
      const { error: deleteError } = await supabase
        .from('blocks')
        .delete()
        .eq('id', blockId)
        .eq('user_id', userId);

      if (deleteError) {
        console.error('Error deleting block:', deleteError);
        throw deleteError;
      }

      return block;
    } catch (error) {
      console.error('Error deleting block:', error);
      throw error;
    }
  },

  // Get blocks by category
  getBlocksByCategory: async (userId, category) => {
    try {
      const { data, error } = await supabase
        .from('blocks')
        .select('*')
        .eq('user_id', userId)
        .eq('category', category)
        .order('updated_at', { ascending: false });

      if (error) {
        console.error('Error fetching blocks by category:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error fetching blocks by category:', error);
      throw error;
    }
  },

  // Search blocks
  searchBlocks: async (userId, searchTerm) => {
    try {
      const { data, error } = await supabase
        .from('blocks')
        .select('*')
        .eq('user_id', userId)
        .or(`title.ilike.%${searchTerm}%,content.ilike.%${searchTerm}%`)
        .order('updated_at', { ascending: false });

      if (error) {
        console.error('Error searching blocks:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error searching blocks:', error);
      throw error;
    }
  }
};

// Prompt Executions service
export const promptExecutionsService = {
  // Save a prompt execution
  saveExecution: async (executionData) => {
    try {
      const { data, error } = await supabase
        .from('prompt_executions')
        .insert([{
          user_id: executionData.userId,
          prompt_id: executionData.promptId,
          prompt_data: executionData.promptData,
          variables: executionData.variables,
          result: executionData.result,
          status: executionData.status,
          execution_time: executionData.executionTime,
          model: executionData.model,
          provider: executionData.provider
        }])
        .select();

      if (error) {
        console.error('Error saving prompt execution:', error);
        throw error;
      }

      return data[0];
    } catch (error) {
      console.error('Error saving prompt execution:', error);
      throw error;
    }
  },

  // Get execution history for a prompt
  getExecutionsByPromptId: async (userId, promptId) => {
    try {
      const { data, error } = await supabase
        .from('prompt_executions')
        .select('*')
        .eq('user_id', userId)
        .eq('prompt_id', promptId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching prompt executions:', error);
        return [];
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching prompt executions:', error);
      return [];
    }
  },

  // Get all executions for a user
  getAllExecutions: async (userId) => {
    try {
      const { data, error } = await supabase
        .from('prompt_executions')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching all prompt executions:', error);
        return [];
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching all prompt executions:', error);
      return [];
    }
  },

  // Delete an execution
  deleteExecution: async (executionId, userId) => {
    try {
      const { error } = await supabase
        .from('prompt_executions')
        .delete()
        .eq('id', executionId)
        .eq('user_id', userId);

      if (error) {
        console.error('Error deleting prompt execution:', error);
        throw error;
      }

      return true;
    } catch (error) {
      console.error('Error deleting prompt execution:', error);
      throw error;
    }
  }
};

// Prompts service
export const promptsService = {
  // Get all prompts for a user
  getPrompts: async (userId) => {
    try {
      console.log('Fetching prompts for user:', userId);

      const { data, error } = await supabase
        .from('prompts')
        .select('*')
        .eq('user_id', userId)
        .order('updated_at', { ascending: false });

      if (error) {
        console.error('Error fetching prompts:', error);
        return [];
      }

      console.log('Prompts response:', data);
      return data || [];
    } catch (error) {
      console.error('Error fetching prompts:', error);
      return [];
    }
  },

  // Get a prompt by ID
  getPromptById: async (promptId) => {
    try {
      const { data, error } = await supabase
        .from('prompts')
        .select('*')
        .eq('id', promptId)
        .single();

      if (error) {
        console.error('Error fetching prompt:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error fetching prompt:', error);
      throw error;
    }
  },

  // Create a new prompt
  createPrompt: async (promptData) => {
    try {
      console.log('Creating prompt with data:', promptData);

      // Remove the id field when creating a new prompt to avoid duplicate key errors
      const { id, ...dataWithoutId } = promptData;

      // Make sure is_favorite is a boolean
      if ('is_favorite' in dataWithoutId) {
        dataWithoutId.is_favorite = Boolean(dataWithoutId.is_favorite);
      }

      const { data, error } = await supabase
        .from('prompts')
        .insert([dataWithoutId])
        .select()
        .single();

      if (error) {
        console.error('Error creating prompt:', error);
        throw error;
      }

      console.log('Create prompt response:', data);
      return data;
    } catch (error) {
      console.error('Error creating prompt:', error);
      throw error;
    }
  },

  // Update an existing prompt
  updatePrompt: async (promptId, promptData) => {
    try {
      console.log('Updating prompt with data:', promptData);

      // Make sure is_favorite is a boolean
      if ('is_favorite' in promptData) {
        promptData.is_favorite = Boolean(promptData.is_favorite);
      }

      const { data, error } = await supabase
        .from('prompts')
        .update(promptData)
        .eq('id', promptId)
        .select()
        .single();

      if (error) {
        console.error('Error updating prompt:', error);
        throw error;
      }

      console.log('Update prompt response:', data);
      return data;
    } catch (error) {
      console.error('Error updating prompt:', error);
      throw error;
    }
  },

  // Delete a prompt
  deletePrompt: async (promptId, userId) => {
    try {
      // First, get the prompt to return it after deletion
      const { data: prompt, error: getError } = await supabase
        .from('prompts')
        .select('*')
        .eq('id', promptId)
        .eq('user_id', userId)
        .single();

      if (getError) {
        console.error('Error fetching prompt for deletion:', getError);
        throw getError;
      }

      // Delete the prompt
      const { error: deleteError } = await supabase
        .from('prompts')
        .delete()
        .eq('id', promptId)
        .eq('user_id', userId);

      if (deleteError) {
        console.error('Error deleting prompt:', deleteError);
        throw deleteError;
      }

      return prompt;
    } catch (error) {
      console.error('Error deleting prompt:', error);
      throw error;
    }
  },

  // Get prompts by folder
  getPromptsByFolder: async (userId, folder) => {
    try {
      const { data, error } = await supabase
        .from('prompts')
        .select('*')
        .eq('user_id', userId)
        .eq('folder', folder)
        .order('updated_at', { ascending: false });

      if (error) {
        console.error('Error fetching prompts by folder:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error fetching prompts by folder:', error);
      throw error;
    }
  },

  // Search prompts
  searchPrompts: async (userId, searchTerm) => {
    try {
      const { data, error } = await supabase
        .from('prompts')
        .select('*')
        .eq('user_id', userId)
        .or(`title.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%,content.ilike.%${searchTerm}%`)
        .order('updated_at', { ascending: false });

      if (error) {
        console.error('Error searching prompts:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Error searching prompts:', error);
      throw error;
    }
  }
};