import React from 'react';
import { Box, Typography, Paper, Divider, Button, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiKeyManager from '../components/settings/ApiKeyManager';

function ApiKeySettings() {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button 
          component={Link} 
          to="/settings" 
          startIcon={<ArrowBackIcon />}
          sx={{ mr: 2 }}
        >
          Back to Settings
        </Button>
        <Typography variant="h4">
          AI Provider API Keys
        </Typography>
      </Box>
      
      <Paper sx={{ p: 3 }}>
        <Typography variant="body1" paragraph>
          Configure API keys for different AI providers to use their models in your prompts.
          Your API keys are stored securely in your browser&apos;s local storage and are never sent to our servers.
        </Typography>
        <Divider sx={{ my: 2 }} />
        
        <ApiKeyManager />
      </Paper>
    </Box>
  );
}

export default ApiKeySettings;