import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  InputAdornment,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Divider,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tooltip,
  Autocomplete
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { promptsService } from '../../services/apiService';
import { useAuth } from '../../contexts/AuthContext';

const ApiPromptLibrary = ({ showFavorites = false }) => {
  const { user } = useAuth();
  const [prompts, setPrompts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterTag, setFilterTag] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPromptId, setSelectedPromptId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Tag management state
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  // Load prompts from the database
  useEffect(() => {
    const loadPrompts = async () => {
      if (!user) return;

      try {
        setLoading(true);
        setError(null);

        console.log('Loading prompts for user:', user.id);
        console.log('User object:', user);

        const data = await promptsService.getPrompts(user.id);
        console.log('Loaded prompts:', data);

        // If no prompts exist, create a sample one
        if (!data || data.length === 0) {
          try {
            console.log('No prompts found, creating a sample prompt');
            const samplePrompt = await promptsService.createPrompt({
              user_id: user.id,
              title: 'Sample Prompt',
              description: 'This is a sample prompt to help you get started.',
              content: 'Write a creative story about a space explorer who discovers a new planet.',
              model: 'gpt-4',
              tags: ['sample', 'creative', 'story'],
              is_favorite: false
            });

            console.log('Created sample prompt:', samplePrompt);
            setPrompts([samplePrompt]);
          } catch (createErr) {
            console.error('Error creating sample prompt:', createErr);
            setError('Failed to create a sample prompt. Please try again later.');
            setPrompts([]);
          }
        } else {
          setPrompts(data);
        }
      } catch (err) {
        console.error('Error loading prompts:', err);
        setError('Failed to load prompts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadPrompts();
  }, [user]);

  // Get unique tags for filter
  const allTags = prompts.reduce((acc, prompt) => {
    if (prompt.tags && Array.isArray(prompt.tags)) {
      prompt.tags.forEach(tag => acc.add(tag));
    }
    return acc;
  }, new Set());
  const tags = [...allTags].sort();

  // Get unique models for filter
  const models = [...new Set(prompts.map(prompt => prompt.model).filter(Boolean))];

  const handleMenuOpen = (event, promptId) => {
    setAnchorEl(event.currentTarget);
    setSelectedPromptId(promptId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPromptId(null);
  };

  const handleToggleFavorite = async (promptId) => {
    try {
      const promptToUpdate = prompts.find(p => p.id === promptId);
      if (!promptToUpdate) return;
      
      const updatedPrompt = await promptsService.updatePrompt(promptId, {
        ...promptToUpdate,
        user_id: user.id,
        is_favorite: !promptToUpdate.is_favorite
      });
      
      setPrompts(prompts.map(prompt => 
        prompt.id === promptId 
          ? { ...prompt, is_favorite: !prompt.is_favorite } 
          : prompt
      ));
    } catch (err) {
      console.error('Error updating prompt:', err);
      setSnackbar({
        open: true,
        message: `Error updating prompt: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    }
  };

  const handleDeletePrompt = async () => {
    try {
      await promptsService.deletePrompt(selectedPromptId, user.id);
      setPrompts(prompts.filter(prompt => prompt.id !== selectedPromptId));
      
      setSnackbar({
        open: true,
        message: 'Prompt deleted successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error deleting prompt:', err);
      setSnackbar({
        open: true,
        message: `Error deleting prompt: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      handleMenuClose();
    }
  };

  const handleDuplicatePrompt = async () => {
    try {
      const promptToDuplicate = prompts.find(p => p.id === selectedPromptId);
      if (!promptToDuplicate) return;
      
      // Create a new prompt based on the duplicated one
      const newPrompt = await promptsService.createPrompt({
        ...promptToDuplicate,
        title: `${promptToDuplicate.title} (Copy)`,
        user_id: user.id
      });
      
      setPrompts([...prompts, newPrompt]);
      
      setSnackbar({
        open: true,
        message: 'Prompt duplicated successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error duplicating prompt:', err);
      setSnackbar({
        open: true,
        message: `Error duplicating prompt: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    } finally {
      handleMenuClose();
    }
  };

  const filteredPrompts = prompts.filter(prompt => {
    const matchesSearch =
      prompt.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (prompt.description && prompt.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (prompt.tags && Array.isArray(prompt.tags) && prompt.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())));

    const matchesModel = filterModel === '' || prompt.model === filterModel;

    const matchesTag =
      filterTag === '' ||
      (prompt.tags && Array.isArray(prompt.tags) && prompt.tags.includes(filterTag));

    const matchesFavorites = !showFavorites || prompt.is_favorite;

    return matchesSearch && matchesModel && matchesTag && matchesFavorites;
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Tag management functions
  const handleOpenTagDialog = (promptId) => {
    const prompt = prompts.find(p => p.id === promptId);
    if (prompt) {
      setSelectedPromptId(promptId);
      setSelectedTags(prompt.tags || []);
      setTagDialogOpen(true);
    }
  };

  const handleCloseTagDialog = () => {
    setTagDialogOpen(false);
    setNewTag('');
    setSelectedTags([]);
    setSelectedPromptId(null);
  };

  const handleAddTag = () => {
    if (!newTag.trim()) return;

    // Check if tag already exists in the selection
    if (selectedTags.includes(newTag)) {
      setSnackbar({
        open: true,
        message: `Tag "${newTag}" already added to this prompt`,
        severity: 'warning'
      });
      return;
    }

    setSelectedTags([...selectedTags, newTag]);
    setNewTag('');
  };

  const handleRemoveTag = (tagToRemove) => {
    setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
  };

  const handleSaveTags = async () => {
    try {
      const promptToUpdate = prompts.find(p => p.id === selectedPromptId);
      if (!promptToUpdate) return;

      const updatedPrompt = await promptsService.updatePrompt(selectedPromptId, {
        ...promptToUpdate,
        user_id: user.id,
        tags: selectedTags
      });

      setPrompts(prompts.map(prompt =>
        prompt.id === selectedPromptId
          ? { ...prompt, tags: selectedTags }
          : prompt
      ));

      setSnackbar({
        open: true,
        message: 'Tags updated successfully',
        severity: 'success'
      });

      handleCloseTagDialog();
    } catch (err) {
      console.error('Error updating tags:', err);
      setSnackbar({
        open: true,
        message: `Error updating tags: ${err.message || 'Unknown error'}`,
        severity: 'error'
      });
    }
  };



  const renderPromptCards = () => {
    return filteredPrompts.map((prompt) => (
      <Grid item xs={12} sm={6} md={4} key={prompt.id}>
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Typography variant="h6" component="div" gutterBottom>
                {prompt.title}
              </Typography>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => handleToggleFavorite(prompt.id)}
                  color="primary"
                >
                  {prompt.is_favorite ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(e) => handleMenuOpen(e, prompt.id)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>



            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {prompt.description || 'No description'}
            </Typography>

            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
              Created: {new Date(prompt.created_at).toLocaleString()}
              {prompt.updated_at && prompt.updated_at !== prompt.created_at &&
                ` • Updated: ${new Date(prompt.updated_at).toLocaleString()}`}
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 1 }}>
              {prompt.tags && Array.isArray(prompt.tags) && prompt.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  size="small"
                  variant="outlined"
                  onClick={() => setFilterTag(tag)}
                  clickable
                  color={filterTag === tag ? "primary" : "default"}
                  icon={<LocalOfferIcon />}
                />
              ))}
            </Box>

            <Chip
              label={prompt.model}
              size="small"
              color="primary"
              variant="outlined"
            />
          </CardContent>
          <CardActions>
            <Button
              size="small"
              variant="contained"
              color="primary"
              component={Link}
              to={`/editor/${prompt.id}`}
              fullWidth
            >
              Use
            </Button>
          </CardActions>
        </Card>
      </Grid>
    ));
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (filteredPrompts.length > 0) {
      return (
        <Grid container spacing={3}>
          {renderPromptCards()}
        </Grid>
      );
    }

    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h6" color="text.secondary">
          No prompts found matching your filters
        </Typography>
        <Button 
          variant="contained" 
          component={Link} 
          to="/editor/new"
          sx={{ mt: 2 }}
        >
          Create New Prompt
        </Button>
      </Box>
    );
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {(showFavorites || filterTag) && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {showFavorites && filterTag && (
            <>Showing favorite prompts with tag "{filterTag}".</>
          )}
          {showFavorites && !filterTag && (
            <>Showing favorite prompts only.</>
          )}
          {!showFavorites && filterTag && (
            <>Showing prompts with tag "{filterTag}".</>
          )}

          {' '}

          {filterTag && (
            <Button
              size="small"
              onClick={() => setFilterTag('')}
            >
              Clear tag filter
            </Button>
          )}

          {showFavorites && (
            <Button size="small" component={Link} to="/library">View all prompts</Button>
          )}
        </Alert>
      )}

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search prompts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Filter by Model</InputLabel>
                <Select
                  value={filterModel}
                  label="Filter by Model"
                  onChange={(e) => setFilterModel(e.target.value)}
                >
                  <MenuItem value="">All Models</MenuItem>
                  {models.map((model) => (
                    <MenuItem key={model} value={model}>{model}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip title={showFavorites ? "Show all prompts" : "Show favorites only"}>
                <IconButton
                  color={showFavorites ? "primary" : "default"}
                  component={Link}
                  to={showFavorites ? "/library" : "/library?filter=favorites"}
                  sx={{ mt: 1 }}
                >
                  <StarIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Autocomplete
                  fullWidth
                  value={filterTag}
                  onChange={(event, newValue) => setFilterTag(newValue || '')}
                  options={tags}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by Tag"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <LocalOfferIcon color="action" />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LocalOfferIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
                        {option}
                      </Box>
                    </li>
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mb: 2 }} />
      
      {renderContent()}
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose} component={Link} to={`/editor/${selectedPromptId}`}>Use</MenuItem>
        <MenuItem onClick={() => {
          handleMenuClose();
          handleOpenTagDialog(selectedPromptId);
        }}>
          <ListItemIcon>
            <LocalOfferIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Manage Tags</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicatePrompt}>Duplicate</MenuItem>
        <MenuItem onClick={handleDeletePrompt}>Delete</MenuItem>
      </Menu>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Tag Management Dialog */}
      <Dialog
        open={tagDialogOpen}
        onClose={handleCloseTagDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Manage Tags</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', mt: 2, mb: 3 }}>
            <Autocomplete
              freeSolo
              fullWidth
              value={newTag}
              onChange={(event, newValue) => setNewTag(newValue || '')}
              inputValue={newTag}
              onInputChange={(event, newInputValue) => setNewTag(newInputValue)}
              options={tags.filter(tag => !selectedTags.includes(tag))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Tag"
                  margin="dense"
                  sx={{ mr: 1 }}
                />
              )}
            />
            <Button
              variant="contained"
              onClick={handleAddTag}
              disabled={!newTag.trim()}
              startIcon={<AddIcon />}
              sx={{ mt: 1, height: 40 }}
            >
              Add
            </Button>
          </Box>

          <Typography variant="subtitle1" gutterBottom>
            Current Tags
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {selectedTags.length > 0 ? (
              selectedTags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleRemoveTag(tag)}
                  color="primary"
                  icon={<LocalOfferIcon />}
                />
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                No tags added yet. Add tags to help organize your prompts.
              </Typography>
            )}
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Tags help you organize and find your prompts quickly. You can filter prompts by tag in the library.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTagDialog}>
            Cancel
          </Button>
          <Button
            onClick={handleSaveTags}
            variant="contained"
          >
            Save Tags
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApiPromptLibrary;