import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  CircularProgress,
  Typography,
  Box,
  Paper
} from '@mui/material';

/**
 * A loading overlay component that shows a message and spinner
 * when API calls are in progress
 */
const LoadingOverlay = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('Loading...');

  useEffect(() => {
    // Listen for API call start events
    const handleApiCallStart = (event) => {
      setMessage(event.detail.message || 'Processing request...');
      setOpen(true);
    };

    // Listen for API call complete events
    const handleApiCallComplete = () => {
      setOpen(false);
    };

    // Add event listeners
    window.addEventListener('api-call-start', handleApiCallStart);
    window.addEventListener('api-call-complete', handleApiCallComplete);

    // Clean up event listeners
    return () => {
      window.removeEventListener('api-call-start', handleApiCallStart);
      window.removeEventListener('api-call-complete', handleApiCallComplete);
    };
  }, []);

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: 'blur(3px)'
      }}
      open={open}
    >
      <Paper
        elevation={4}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '80%'
        }}
      >
        <CircularProgress color="primary" sx={{ mb: 2 }} />
        <Typography variant="h6" component="div" gutterBottom>
          {message}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          This may take a few moments depending on the complexity of your prompt.
        </Typography>
      </Paper>
    </Backdrop>
  );
};

export default LoadingOverlay;